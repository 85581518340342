import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikTextField from "../../../../../components/inputs/FormikTextField";
import FormikDateField from "../../../../../components/inputs/FormikDateField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormikSelectField from "../../../../../components/inputs/FormikSelectField";
import FormikUploadField from "../../../../../components/inputs/FormikUploadField";
import Typography from "@material-ui/core/Typography";
import {
  API_URL,
  ROUTES,
  DOCUMENT_UPLOAD_STATUS_OPTIONS,
} from "../../../../../constants";
import FormLabel from "@material-ui/core/FormLabel";

export const initialValues = {
  residentDocumentType: "TURNING_RECORDS",
  documentPath: "",
  residentDocumentStatus: "",
  approver: "",
  remarks: "",
};

export const validationSchema = Yup.object().shape({
  documentPath: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  residentDocumentStatus: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export const TurningRecordsForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                <FormikSelectField
                  label="審查狀態"
                  name="residentDocumentStatus"
                  options={DOCUMENT_UPLOAD_STATUS_OPTIONS}
                />
              </Grid>

              <Grid item sm={8} xs={12}>
                <FormikTextField label="批核人" name="approver" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="備註" name="remarks" />
              </Grid>
            </Grid>

            <Grid item sm={12} xs={12} style={{ marginTop: 15 }}>
              <fieldset>
                <FormLabel component="legend">文件上載</FormLabel>

                <FormikUploadField
                  required
                  label="文件"
                  name="documentPath"
                  policy="RESIDENT_DOCUMENT"
                  document
                />
              </fieldset>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
