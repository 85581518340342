import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import { API_URL, ROUTES, FOLLOW_UP_STATUS_OPTION } from "../../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./MealRecord/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./MealRecord/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ListingPageHeader } from "./MealRecord/ListingPageHeader";
import { useParams } from "react-router-dom";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { NotificationActions } from "../../../actions";
import { EditDialog } from "./MealRecord/EditDialog";

export default function MealRecord() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  let { id } = useParams();
  const [editEntity, setEditEntity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [editOpen, setEditOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.recordDateTime = values.recordDateTime;
    params.remarks = values.remarks;
    params.resident = id;

    return params;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 160,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton color="default" size="small" disabled={loading}>
                                <AssignmentIndOutlinedIcon />
                            </IconButton> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "createdAt",
        headerName: "記錄日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]?.substring(0, 10)
            : "--";
        },
      },
      {
        field: "recordDateTime",
        headerName: "膳食記錄日期及時間",
        width: 170,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return moment(params.row[params.field]).format(
            "YYYY-MM-DD HH:mm"
          );
        },
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 380,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  const onEdit = (values, formikActions) => {
    axios
      .put(API_URL.MEAL_RECORD + "/" + values.id, {
        recordDateTime: values.recordDateTime,
        remarks: values.remarks,
        resident: id,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setEditEntity(null);
        formikActions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.MEAL_RECORD + "?resident=" + id,
      getOne: API_URL.MEAL_RECORD,
      create: API_URL.MEAL_RECORD,
      edit: API_URL.MEAL_RECORD,
      delete: API_URL.MEAL_RECORD,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="膳食記錄"
      editableFields={[
        "recordDateTime",
        "remarks",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      // createFilteringParams={createFilteringParams}
      // normalizeReceivedValues={normalizeReceivedValues}
      // filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={
        {
          // handleFilterChange: handleFilterChange,
        }
      }
      maxWidth="md"
      defaultSorting={"updatedAt,desc"}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setLoading(false);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        // normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
