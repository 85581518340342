import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API_URL } from "../../constants";
import { Notification } from "../../components";
import { PageHeader } from "../../components";
import Image from "../../components/Image";

const useStyles = makeStyles((theme) => ({
  table: {
    [theme.breakpoints.down("md")]: {
      "& tr, & thead, & tbody, & tfoot, & th, & td": {
        display: "block",
      },
    },
  },
  logo: {
    maxWidth: 150,
    maxHeight: 150,
  },
  rowHead: {
    width: 250,
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderBottom: 0,
    },
  },
}));

export default function HomeInfoPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [entity, setEntity] = useState({});

  const getEntity = () => {
    if (home.home) {
      axios
        .get(API_URL.HOME + "/" + home.home.id)
        .then((response) => {
          let data = response.data;
          setEntity(data);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    getEntity();
  }, [home]);

  return (
    <Fragment>
      {home && (
        <Fragment>
          <PageHeader title={"院舍資料"} />
          <Notification />
          <TableContainer component={Paper} style={{ padding: 20 }}>
            <Table className={classes.table} size="small">
              <TableBody>
                <TableRow>
                  <TableCell className={classes.rowHead}>院舍標誌</TableCell>
                  <TableCell>
                    {entity.logo && (
                      <Image
                        style={{ maxWidth: 150, maxHeight: 150 }}
                        url={entity.logo}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>
                    院舍名稱(英文)
                  </TableCell>
                  <TableCell>{entity.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>
                    院舍名稱(中文)
                  </TableCell>
                  <TableCell>{entity.nameTc}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>地址(英文)</TableCell>
                  <TableCell>{entity.address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>地址(中文)</TableCell>
                  <TableCell>{entity.addressTc}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>電話</TableCell>
                  <TableCell>
                    {entity.tel && (
                      <a href={"tel:" + entity.tel}>{entity.tel}</a>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>傳真</TableCell>
                  <TableCell>{entity.fax && entity.fax}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>網站</TableCell>
                  <TableCell>
                    {entity.website && (
                      <a href={"//" + entity.website}>{entity.website}</a>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>電郵</TableCell>
                  <TableCell>
                    {entity.email && (
                      <a href={"mailto:" + entity.email}>{entity.email}</a>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>
                    安老院牌照號碼
                  </TableCell>
                  <TableCell>{entity.license}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>
                    牌照有效期至
                  </TableCell>
                  <TableCell>
                    {entity.licenseExpiryDate?.substring(0, 10)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>
                    商業登記號碼
                  </TableCell>
                  <TableCell>{entity.brNo}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>院舍性質</TableCell>
                  <TableCell>{entity.typeOfService}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>
                    服務宗旨及目標
                  </TableCell>
                  <TableCell>{entity.servicePurposeAndGoals}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.rowHead}>平面圖</TableCell>
                  <TableCell>
                    {entity.floorPlanImgPath && (
                      <Image
                        style={{ maxWidth: 650, maxHeight: 650 }}
                        url={entity.floorPlanImgPath}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      )}
    </Fragment>
  );
}
