import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL } from "../../constants";
import { apiService } from "../../services";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import fileDownload from "js-file-download";
import { UploadDialog } from "./components/UploadDialog";
import { CreateFolderDialog } from "./components/CreateFolderDialog";
import axios from "axios";
import { NotificationActions } from "../../actions";

export default function DocumentManagementPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [loadingDownload, setLoading] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [injectErrorMsg, setInjectErrorMsg] = useState("");
  const [currentFolderId, setCurrentFolderId] = useState("");
  const [folderNav, setFolderNav] = useState([]);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    return params;
  };

  const normalizeReceivedValues = (values) => {};

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const onDownload = (values) => {
    apiService
      .get(values.url, { responseType: "blob" })
      .then(function (response) {
        let data = response.data;
        setLoading(false);
        fileDownload(data, values.name);
      })
      .catch(function (response) {
        setLoading(false);
      });
  };

  const onUploadFile = (values, actions) => {
    if ((values.files || []).length === 1) {
      const uploadFile = values.files[0];

      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("policy", "DOCUMENT");
      if (values.folder) formData.append("folder", values.folder);

      axios
        .post(API_URL.DOCUMENT_UPLOAD, formData)
        .then((response) => {
          if (uploadFile.status === "failed") return;
          uploadFile.status = "successful";
          dispatch(NotificationActions.success(null, "已儲存"));
          actions.setSubmitting(false);
          setUploadOpen(false);
          setReload(!reload);
        })
        .catch(() => {
          dispatch(NotificationActions.error(null, "錯誤"));
          actions.setSubmitting(false);
        });
    }
    else {
      console.log("trying");
      setInjectErrorMsg("請上傳單個檔案");
      actions.setSubmitting(false);
    }
  }

  const onCreateFolder = (values, actions) => {
    axios
      .post(API_URL.DOCUMENT_FOLDER, {
        name: values.name,
        folder: values.folder,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
        setCreateFolderOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          if (params.row['url']) {
            return (
              <div>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => {
                      clearNotification();
                      onDownload(params.row);
                    }}
                    disabled={loadingDownload}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="刪除">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => {
                      clearNotification();
                      setDeletingEntity(params.row);
                    }}
                    disabled={loading}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          } else {
            return (
              <div>
                <Tooltip title="查看">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => {
                      clearNotification();
                      setCurrentFolderId(params.row['id']);
                      setFolderNav([...folderNav, {
                        id: params.row['id'],
                        name: params.row['name']
                      }])
                    }}
                    disabled={loading}
                  >
                    <FolderOpenOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          }
        },
      },
      {
        field: "name",
        headerName: "文件名稱",
        width: 1000,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      // getAll: API_URL.DOCUMENT,
      getAll: (currentFolderId) ? `${API_URL.DOCUMENT_FOLDER}/${currentFolderId}` : API_URL.DOCUMENT_FOLDER,
      getOne: API_URL.DOCUMENT,
      create: API_URL.DOCUMENT,
      edit: API_URL.DOCUMENT,
      delete: API_URL.DOCUMENT_DELETE,
      download: API_URL.DOCUMENT,
    });
  }, [home, reload, currentFolderId]);

  return (
    <EntityManagement
      entityModel="文件"
      editableFields={["name"]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        setUploadOpen: setUploadOpen,
        setCreateFolderOpen: setCreateFolderOpen,
        handleFilterChange: handleFilterChange,
        setCurrentFolderId: setCurrentFolderId,
        folderNav: folderNav,
        setFolderNav: setFolderNav,
      }}
      maxWidth="sm"
      listDetailsVariation={"document-folder"}
      currentFolderId={currentFolderId}
    >
      <UploadDialog
        open={uploadOpen}
        onClose={() => {
          setUploadOpen(false);
          setReload(!reload);
        }}
        onSubmit={onUploadFile}
        injectErrorMsg={injectErrorMsg}
        setInjectErrorMsg={setInjectErrorMsg}
      />
      <CreateFolderDialog
        open={createFolderOpen}
        onClose={() => {
          setCreateFolderOpen(false);
          setReload(!reload);
        }}
        onSubmit={onCreateFolder}
      />
    </EntityManagement>
  );
}
