import * as Yup from "yup";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext, FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikCheckboxField from "../../../components/inputs/FormikCheckboxField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikTextAreaField from "../../../components/inputs/FormikTextAreaField";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import {
  API_URL,
  AUXILIARY_TOOLS_OPTIONS,
  COGNITIVE_ABILITY_OPTIONS,
  COMMUNICATION_ABILITY_OPTIONS,
  DENTURE_OPTIONS,
  EATING_ABILITY_OPTIONS,
  EXCRETION_OPTIONS,
  HEARING_OPTIONS,
  HUG_OPTIONS,
  SELF_CARE_ABILITY_OPTIONS,
  VISUAL_OPTIONS,
} from "../../../constants";
import { NotificationActions } from "../../../actions";
import { useDispatch } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const initialValues = {
  selfCareAbility: "",
  visual: "",
  hearing: "",
  cognitiveAbility: "",
  hug: "",
  specialHabits: "",
  communicationAbility: "",
  excretion: "",
  auxiliaryTools: "",
  denture: "",
  contactPrecaution: false,
  selfCareAbility: "",
  oxygen: false,
  dining: [],
  dietList: [],
  memoList: [],
  diaperList: [],
};

const validationSchema = Yup.object().shape({
  dietList: Yup.array().of(
    Yup.object().shape({
      dietId: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .typeError(<FormattedMessage id="validation.message.required" />)
        .nullable(),
    })
  ),
  memoList: Yup.array().of(
    Yup.object().shape({
      memoId: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .typeError(<FormattedMessage id="validation.message.required" />)
        .nullable(),
    })
  ),
  diaperList: Yup.array().of(
    Yup.object().shape({
      diaperId: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .typeError(<FormattedMessage id="validation.message.required" />)
        .nullable(),
    })
  ),
});

export const HealthFormContent = (props) => {
  const { entity, ...rest } = props;
  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleChange,
    isSubmitting,
    handleSubmit,
    resetForm,
    initialValues,
  } = useFormikContext();

  useEffect(() => {
    resetForm({ values: { ...initialValues, ...entity } });
  }, [entity]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <fieldset>
            <FormLabel component="legend">個人能力評估</FormLabel>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="自理能力"
                  name="selfCareAbility"
                  options={SELF_CARE_ABILITY_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="視覺"
                  name="visual"
                  options={VISUAL_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                {" "}
                <FormikSelectField
                  label="進食能力"
                  name="eatingAbility"
                  options={EATING_ABILITY_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="聽覺"
                  name="hearing"
                  options={HEARING_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="認知能力"
                  name="cognitiveAbility"
                  options={COGNITIVE_ABILITY_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="扶抱"
                  name="hug"
                  options={HUG_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="溝通能力"
                  name="communicationAbility"
                  options={COMMUNICATION_ABILITY_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="排泄"
                  name="excretion"
                  options={EXCRETION_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="輔助工具"
                  name="auxiliaryTools"
                  options={AUXILIARY_TOOLS_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="假牙"
                  name="denture"
                  options={DENTURE_OPTIONS}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikCheckboxField
                  label="接觸傳染防護措施"
                  name="contactPrecaution"
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikCheckboxField label="氧氣" name="oxygen" />
              </Grid>
            </Grid>
          </fieldset>
        </Grid>

        <Grid item md={8} xs={12}>
          <fieldset style={{ marginBottom: 15 }}>
            <FormLabel component="legend">飲食</FormLabel>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  label="用餐"
                  name="diningList.0.diningId"
                  apiUrl={API_URL.DINING + "/references"}
                  getOptionLabel={(option) => option?.nameTc}
                  apiParams={{
                    unpaged: true,
                  }}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <FieldArray
                  name="dietList"
                  render={(arrayHelpers) => (
                    <div>
                      <TableContainer style={{ maxHeight: 400 }}>
                        <Table
                          stickyHeader
                          padding="none"
                          style={{ marginBottom: 15 }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component="th" style={{ padding: 5 }}>
                                類別
                              </TableCell>
                              <TableCell component="th" style={{ padding: 5 }}>
                                項目
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ width: 50 }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.dietList &&
                              values.dietList.map((diet, index) => (
                                <TableRow key={index} disablegutters>
                                  <TableCell
                                    style={{ paddingRight: 1, paddingTop: 3 }}
                                  >
                                    <FormikReferenceField
                                      edit={entity ? true : false}
                                      label="類別"
                                      name={`dietList.${index}.dietId`}
                                      apiUrl={API_URL.DIET + "/references"}
                                      apiParams={{
                                        unpaged: true,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormikTextField
                                      label="項目"
                                      name={`dietList.${index}.item`}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <DeleteOutlinedIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        color="default"
                        variant="contained"
                        type="button"
                        size="small"
                        onClick={() =>
                          arrayHelpers.push({ dietId: null, item: null })
                        }
                      >
                        新增
                      </Button>
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </fieldset>

          <Grid item md={12} xs={12}>
            <fieldset style={{ marginBottom: 15 }}>
              <FormLabel component="legend">其他</FormLabel>
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <FormikTextAreaField
                    label="特殊的生活習慣或嗜好"
                    name="specialHabits"
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>
          <fieldset style={{ marginBottom: 15 }}>
            <FormLabel component="legend">備忘錄</FormLabel>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FieldArray
                  name="memoList"
                  render={(arrayHelpers) => (
                    <div>
                      <TableContainer style={{ maxHeight: 400 }}>
                        <Table
                          stickyHeader
                          padding="none"
                          style={{ marginBottom: 15 }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component="th" style={{ padding: 5 }}>
                                項目
                              </TableCell>
                              <TableCell component="th" style={{ padding: 5 }}>
                                備註
                              </TableCell>
                              <TableCell
                                component="th"
                                style={{ width: 170, padding: 5 }}
                              >
                                開始日期
                              </TableCell>
                              <TableCell
                                component="th"
                                style={{ width: 170, padding: 5 }}
                              >
                                結束日期
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ width: 50 }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.memoList &&
                              values.memoList.map((memo, index) => (
                                <TableRow key={index} disablegutters>
                                  <TableCell
                                    style={{ paddingRight: 1, paddingTop: 3 }}
                                  >
                                    <FormikReferenceField
                                      edit={entity ? true : false}
                                      label="項目"
                                      name={`memoList.${index}.memoId`}
                                      apiUrl={API_URL.MEMO + "/references"}
                                      apiParams={{
                                        unpaged: true,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell style={{ paddingRight: 1 }}>
                                    <FormikTextField
                                      label="備註"
                                      name={`memoList.${index}.content`}
                                    />
                                  </TableCell>
                                  <TableCell style={{ paddingRight: 1 }}>
                                    <FormikDateField
                                      label="開始日期"
                                      name={`memoList.${index}.startDate`}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormikDateField
                                      label="結束日期"
                                      name={`memoList.${index}.endDate`}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <DeleteOutlinedIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        color="default"
                        variant="contained"
                        type="button"
                        size="small"
                        onClick={() =>
                          arrayHelpers.push({
                            memoId: null,
                            content: null,
                            startDate: null,
                            endDate: null,
                          })
                        }
                      >
                        新增
                      </Button>
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </fieldset>
          <fieldset style={{ marginBottom: 15 }}>
            <FormLabel component="legend">尿片</FormLabel>
            <Grid item sm={12} xs={12}>
              <FieldArray
                name="diaperList"
                render={(arrayHelpers) => (
                  <div>
                    <TableContainer style={{ maxHeight: 400 }}>
                      <Table
                        stickyHeader
                        padding="none"
                        style={{ marginBottom: 15 }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell omponent="th" style={{ padding: 5 }}>
                              項目名稱
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ width: 50 }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.diaperList &&
                            values.diaperList.map((diaper, index) => (
                              <TableRow key={index} disablegutters>
                                <TableCell style={{ paddingTop: 3 }}>
                                  <FormikReferenceField
                                    edit={entity ? true : false}
                                    label="項目名稱"
                                    name={`diaperList.${index}.diaperId`}
                                    apiUrl={API_URL.DIAPER + "/references"}
                                    apiParams={{
                                      unpaged: true,
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <DeleteOutlinedIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Button
                      color="default"
                      variant="contained"
                      type="button"
                      size="small"
                      onClick={() => arrayHelpers.push("")}
                    >
                      新增
                    </Button>
                  </div>
                )}
              />
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
      <Button
        style={{ marginRight: 15 }}
        color="primary"
        variant="contained"
        type="submit"
        disabled={isSubmitting}
      >
        儲存
      </Button>
      <Button
        variant="contained"
        color="default"
        type="reset"
        onClick={resetForm}
      >
        重設
      </Button>
    </form>
  );
};

export const HealthForm = (props) => {
  const { ...rest } = props;
  let { id } = useParams();
  const [entity, setEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const createParams = (values) => {
    let params = {};

    [
      "selfCareAbility",
      "visual",
      "eatingAbility",
      "hearing",
      "cognitiveAbility",
      "hug",
      "specialHabits",
      "communicationAbility",
      "excretion",
      "auxiliaryTools",
      "denture",
      "contactPrecaution",
      "oxygen",
      "diningList",
      "dietList",
      "memoList",
      "diaperList",
    ].map((key) => {
      params[key] = values[key];
    });

    return params;
  };

  const handleSubmit = (values, actions) => {
    axios
      .put(API_URL.RESIDENT + "/" + id + "/health", createParams(values))
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    axios
      .get(API_URL.RESIDENT + "/" + id)
      .then((response) => {
        setEntity(response.data);
        setLoaded(true);
      })
      .catch((error) => {});
  }, [id]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => loaded && <HealthFormContent entity={entity} />}
    </Formik>
  );
};
