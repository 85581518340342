import moment from 'moment';
import { useState, useEffect } from 'react';
import { API_URL, ROUTES, RESIDENT_INVOICE_STATUS_OPTION, EXPENSE_TRANSACTION_METHOD_OPTION } from '../../../constants';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import axios from 'axios';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import fileDownload from "js-file-download";

export const initialValues = {

}

export function DetailsDialog(props) {
    const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } = props;
    const [entityValues, setEntityValues] = useState();
    const [loaded, setLoaded] = useState(false);

    const onPrintReceipt = (values, formikActions) => {
        axios.get((apiUrl.print ? (apiUrl.print + "Payments/print/" + values) : apiUrl), { responseType: 'blob' }).then(function (response) {
            let data = response.data;
            fileDownload(data, moment().format('YYYY-MM-DD_HH_mm_SS') + "_院友收據_print.docx");
        })
    }

    useEffect(() => {
        if (entity) {
            axios.get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + '/' + entity.id).then((response) => {
                let data = response?.data;
                let entityValues = { ...initialValues, ...data };
                // normalizeReceivedValues(entityValues);
                setEntityValues(entityValues);
            }).catch((error) => {
            }).finally(() => {
                setLoaded(true);
            });
        } else {
            setEntityValues(initialValues);
        }
    }, [entity]);

    return (

        <Dialog
            open={open}
            keepMounted
            maxWidth="lg"
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"發票詳細 (" + entityValues?.invoiceId + ")"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ width: 150 }}>院友姓名</TableCell>
                                <TableCell style={{ width: 600 }}>{entityValues?.resident?.lastnameTc + entityValues?.resident?.firstnameTc}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>發票編號</TableCell>
                                <TableCell>{entityValues?.invoiceId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>發票日期及時間</TableCell>
                                <TableCell>{entityValues?.issueDateTime?.substring(0, 10) + ' / ' + entityValues?.issueDateTime?.substring(11, 16)}</TableCell>
                            </TableRow>

                                                        <TableRow>
                                <TableCell>發票日期及時間</TableCell>
                                <TableCell>{entityValues?.issueDateTime?.substring(0, 10) + ' / ' + entityValues?.issueDateTime?.substring(11, 16)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>發票狀態</TableCell>
                                <TableCell>{RESIDENT_INVOICE_STATUS_OPTION.find(elem => elem.value == entityValues?.status)?.label}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>發票金額</TableCell>
                                <TableCell>$ {" "} {entityValues?.total}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>收費項目</TableCell>
                                <TableCell>{entityValues &&
                                    entityValues.residentInvoiceItemList &&
                                    entityValues.residentInvoiceItemList.length > 0 && (
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>收費項目名稱</TableCell>
                                                    <TableCell>描述</TableCell>
                                                    <TableCell>數量</TableCell>
                                                    <TableCell>單價</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {entityValues.residentInvoiceItemList.map(item => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell>{item.invoiceItem.nameTc}</TableCell>
                                                        <TableCell>{item.description}</TableCell>
                                                        <TableCell>{item.qty} </TableCell>
                                                        <TableCell>$ {item.price} </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>付款記錄</TableCell>
                                <TableCell>{(entityValues &&
                                    entityValues.residentInvoicePaymentList) ? (
                                    entityValues.residentInvoicePaymentList.length > 0 && (
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>動作</TableCell>
                                                    <TableCell>付款方式</TableCell>
                                                    <TableCell>付款日期</TableCell>
                                                    <TableCell>總額</TableCell>
                                                    <TableCell>單價</TableCell>
                                                    <TableCell>數量</TableCell>
                                                    <TableCell>備註</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {entityValues.residentInvoicePaymentList.map(item => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell>
                                                            <Tooltip title="列印收據">
                                                                <IconButton size="small" onClick={() => onPrintReceipt(item.id)}>
                                                                    <PrintOutlinedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell>{EXPENSE_TRANSACTION_METHOD_OPTION.find(elem => elem.value == item.paymentMethod)?.label}</TableCell>
                                                        <TableCell>{item.paymentDate?.substring(0, 10) + ' / ' + item.paymentDate?.substring(11, 16)}</TableCell>
                                                        <TableCell>$ {item.total} </TableCell>
                                                        <TableCell>$ {item.amount} </TableCell>
                                                        <TableCell>{item.qty}</TableCell>
                                                        <TableCell>{item.remarks ? item.remarks : "--"} </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )) : "未有任何付款記錄"}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={onClose} >
                    關閉
                </Button>
            </DialogActions>
        </Dialog>
    );
}

