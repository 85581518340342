import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./history";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { DEFAULT_COMPANY_CONFIG } from "./config/app.config";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import HomeManagementPage from "./pages/HomeManagementPage";
import CalendarPage from "./pages/CalendarPage";
import BedManagementPage from "./pages/BedManagementPage";
import RoleManagementPage from "./pages/RoleManagementPage";
import ResidentManagementPage from "./pages/ResidentManagementPage";
import ReservationManagementPage from "./pages/ReservationManagementPage";
import DownPaymentRecordPage from "./pages/DownPaymentRecordPage";
import DischargeManagementPage from "./pages/DischargeManagementPage";
import DeathRecordPage from "./pages/DeathRecordPage";
import LeaveRecordPage from "./pages/LeaveRecordPage";
import ResidentStatisticsPage from "./pages/ResidentStatisticsPage";
import ResidentSettingPage from "./pages/ResidentSettingPage";
import ResidentPage from "./pages/ResidentPage";
import DefaultExpensePage from "./pages/DefaultExpensePage";
import ResidentInvoicePage from "./pages/ResidentInvoicePage";
import HomeExpensePage from "./pages/HomeExpensePage";
import HomeExpensePaymentRecordPage from "./pages/HomeExpensePaymentRecordPage";
import HomeExpensePettyCashPage from "./pages/HomeExpensePettyCashPage";
import HomeExpensePettyCashExpensePage from "./pages/HomeExpensePettyCashExpensePage";
import ResidentDepositPage from "./pages/ResidentDepositPage";
import StaffSalaryPage from "./pages/StaffSalaryPage";
import AccountingSettingPage from "./pages/AccountingSettingPage";
import MedicineManagementPage from "./pages/MedicineManagementPage";
import MedicineReportPage from "./pages/MedicineReportPage";
import MedicineSettingPage from "./pages/MedicineSettingPage";
import MedicalRecordManagementPage from "./pages/MedicalRecordManagementPage";
import ResidentFollowUpManagementPage from "./pages/ResidentFollowUpManagementPage";
import ResidentTreatmentItemsPage from "./pages/ResidentTreatmentItemsPage";
import ResidentEmergencyRecordPage from "./pages/ResidentEmergencyRecordPage";
import MedicalSettingPage from "./pages/MedicalSettingPage";
import ResidentVitalSignsPage from "./pages/ResidentVitalSignsPage";
import WoundManagementPage from "./pages/WoundManagementPage";
import ResidentRestraintItemsPage from "./pages/ResidentRestraintItemsPage";
import IcpPage from "./pages/IcpPage";
import NursingSettingPage from "./pages/NursingSettingPage";
import StaffUsersPage from "./pages/StaffUsersPage";
import RosterRecurringPage from "./pages/RosterRecurringPage";
import RosterManagementPage from "./pages/RosterManagementPage";
import RosterDetailsPage from "./pages/RosterDetailsPage";
import WristbandManagementPage from "./pages/WristbandManagementPage";
import WristbandSettingPage from "./pages/WristbandSettingPage";
import AntiWanderingOverallPage from "./pages/AntiWanderingOverallPage";
import AntiWanderingSettingPage from "./pages/AntiWanderingSettingPage";
import HomeInfoPage from "./pages/HomeInfoPage";
import LocaleService from "./services/locale.service";
import { PrivateRoute, PublicRoute } from "./routes";
import { NotificationActions } from "./actions";
import store from "./stores";
import theme from "./themes";
import "./App.css";
import { ROUTES } from "./constants";
import { ACCESS_TOKEN_KEY, USER_KEY } from "./constants/auth.constant";
import { axiosConfig } from "./config";
import DocumentManagementPage from "./pages/DocumentManagementPage";
import DocumentListingPage from "./pages/DocumentListingPage";
import Cookies from "universal-cookie";
//require("moment-timezone").tz.setDefault("Asia/Hong_Kong");

function AppWrapper() {
  const [locale, setLocale] = useState(LocaleService.getLocale());
  const [companyConfig, setCompanyConfig] = useState(DEFAULT_COMPANY_CONFIG);
  const [defaultHome, setDefaultHome] = useState(DEFAULT_COMPANY_CONFIG);

  const handleLocaleChange = (toLocale) => {
    setLocale(LocaleService.setLocale(toLocale));
  };

  useEffect(() => {
    const cookiestored = new Cookies();
    const cookies = new Cookies();
  }, []);

  useEffect(() => {
    document.title = companyConfig["name"];
    document
      .querySelector('[rel="icon"]')
      .setAttribute("href", companyConfig["logo"]);
    document.body.style.backgroundImage = `url(${companyConfig["bg"]})`;

    window.setLocale = handleLocaleChange;
    window.companyConfig = companyConfig;
  }, []);

  return (
    <Provider store={store}>
      <IntlProvider
        locale={locale}
        key={locale}
        defaultLocale={LocaleService.deaultLocale}
        messages={LocaleService.messages[locale]}
      >
        <App />
      </IntlProvider>
    </Provider>
  );
}

function App() {
  const dispatch = useDispatch();

  axiosConfig.setAuthorizationHeader(localStorage.getItem(ACCESS_TOKEN_KEY));

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(NotificationActions.clear());
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router basename={""} history={history}>
        <Switch>
          <PrivateRoute
            exact
            path={ROUTES.DASHBOARD}
            component={DashboardPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.HOME_OVERVIEW}
            component={DashboardPage}
          />
          <PrivateRoute exact path={ROUTES.CALENDAR} component={CalendarPage} />
          <PrivateRoute
            exact
            path={ROUTES.HOME_MANAGEMENT}
            component={HomeManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.BED_MANAGEMENT}
            component={BedManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.HOME_INFO}
            component={HomeInfoPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.ROLE_MANAGEMENT}
            component={RoleManagementPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.RESIDENT_MANAGEMENT}
            component={ResidentManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.RESERVATION_MANAGEMENT}
            component={ReservationManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.DOWN_PAYMENT_RECORD}
            component={DownPaymentRecordPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.DISCHARGE_MANAGEMENT}
            component={DischargeManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.DEATH_RECORD}
            component={DeathRecordPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.LEAVE_RECORD}
            component={LeaveRecordPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.RESIDENT_STATISTICS}
            component={ResidentStatisticsPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.RESIDENT_SETTING}
            component={ResidentSettingPage}
          />
          <PrivateRoute exact path={ROUTES.RESIDENT} component={ResidentPage} />

          <PrivateRoute
            exact
            path={ROUTES.DEFAULT_EXPENSE}
            component={DefaultExpensePage}
          />
          <PrivateRoute
            exact
            path={ROUTES.RESIDENT_INVOICE}
            component={ResidentInvoicePage}
          />
          <PrivateRoute
            exact
            path={ROUTES.HOME_EXPENSE}
            component={HomeExpensePage}
          />
          <PrivateRoute
            exact
            path={ROUTES.HOME_EXPENSE_RECORD}
            component={HomeExpensePaymentRecordPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.HOME_EXPENSE_PETTY_CASH}
            component={HomeExpensePettyCashPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.HOME_EXPENSE_PETTY_CASH_EXPENSE}
            component={HomeExpensePettyCashExpensePage}
          />
          <PrivateRoute
            exact
            path={ROUTES.RESIDENT_DEPOSIT}
            component={ResidentDepositPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.STAFF_SALARY}
            component={StaffSalaryPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.ACCOUNTING_SETTING}
            component={AccountingSettingPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.MEDICINE_MANAGEMENT}
            component={MedicineManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.MEDICINE_REPORT}
            component={MedicineReportPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.MEDICINE_SETTING}
            component={MedicineSettingPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.MEDICAL_RECORD_MANAGEMENT}
            component={MedicalRecordManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.RESIDENT_FOLLOW_UP_MANAGEMENT}
            component={ResidentFollowUpManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.MEDICAL_ITEM}
            component={ResidentTreatmentItemsPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.RESIDENT_EMERGENCY_RECORD_MANAGEMENT}
            component={ResidentEmergencyRecordPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.MEDICAL_SETTING}
            component={MedicalSettingPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.RESIDENT_VITAL_SIGN}
            component={ResidentVitalSignsPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.WOUND_MANAGEMENT}
            component={WoundManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.RESIDENT_RESTRAINT_ITEM}
            component={ResidentRestraintItemsPage}
          />
          <PrivateRoute exact path={ROUTES.ICP} component={IcpPage} />
          <PrivateRoute
            exact
            path={ROUTES.NURSING_SETTING}
            component={NursingSettingPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.STAFF_USERS}
            component={StaffUsersPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.ROSTER_RECURRING}
            component={RosterRecurringPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.ROSTER_MANAGEMENT}
            component={RosterManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.ROSTER_DETAILS}
            component={RosterDetailsPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.WRISTBAND_MANAGEMENT}
            component={WristbandManagementPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.WRISTBAND_SETTING}
            component={WristbandSettingPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.IBEACON_MANAGEMENT}
            component={AntiWanderingOverallPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.IBEACON_SETTING}
            component={AntiWanderingSettingPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.DOCUMENT_LISTING}
            component={DocumentListingPage}
          />

          <PrivateRoute
            exact
            path={ROUTES.DOCUMENT_MANAGEMENT}
            component={DocumentManagementPage}
          />

          <PublicRoute exact path={ROUTES.LOGIN} component={LoginPage} />
          <Redirect from="*" to={ROUTES.DASHBOARD} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default AppWrapper;
