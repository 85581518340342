import * as Yup from "yup";
import React from "react";
import moment from "moment";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikMultiReferenceField from "../../../components/inputs/FormikMultiReferenceField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import {
  API_URL,
  FOLLOW_UP_STATUS_OPTION,
  RESERVATION_STATUS_OPTIONS,
} from "../../../constants";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

export const initialValues = {
  resident: [],
  date: moment(new Date()).toISOString(),
  institution: "",
  specialist: "",
  division: "",
  medicalFollowUpItems: [],
  transport: "",
  escorterName: "",
  escorterRelationship: "",
  remarks: "",
  status: "",
  finishDate: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.array()
    .min(1, "必須填寫一位或以上院友")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  date: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  institution: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  specialist: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              院友資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikMultiReferenceField
                  required
                  label="院友姓名"
                  name="resident"
                  apiUrl={
                    "/api/homes/" + home.home.id + "/residents/references"
                  }
                  getOptionLabel={(option) =>
                    option.lastnameTc + option.firstnameTc
                  }
                />
                <FormHelperText id="standard-weight-helper-text">
                  可選擇多於一位院友
                </FormHelperText>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  required
                  label="覆診狀態"
                  name="status"
                  options={FOLLOW_UP_STATUS_OPTION}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
              覆診資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikDateTimeField
                  required
                  label="覆診日期及時間"
                  name="date"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="醫院 / 診所"
                  name="institution"
                  apiUrl={"/api/institutions"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) =>
                    option.code
                      ? option.code + " - " + option.nameTc
                      : option.nameTc
                  }
                  getOptionValue={(option) => option.id}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="專科"
                  name="specialist"
                  apiUrl={"/api/specialists"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.nameTc
                  }
                  getOptionValue={(option) => option.id}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  // required
                  label="部類"
                  name="division"
                  apiUrl={"/api/division"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.nameTc
                  }
                  getOptionValue={(option) => option?.id}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <fieldset>
                  <FormLabel component="legend">覆診事項</FormLabel>
                  <FieldArray
                    name="medicalFollowUpItems"
                    render={(arrayHelpers) => (
                      <div>
                        <TableContainer style={{ maxHeight: 400 }}>
                          <Table
                            stickyHeader
                            padding="none"
                            style={{ marginBottom: 15 }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  項目
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: 50 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.medicalFollowUpItems &&
                                values.medicalFollowUpItems.map(
                                  (item, index) => (
                                    <TableRow key={index} disablegutters>
                                      <TableCell style={{ paddingRight: 1 }}>
                                        <FormikReferenceField
                                          edit={entity ? true : false}
                                          label="項目"
                                          apiUrl={
                                            API_URL.MEDICAL_FOLLOW_UP_ITEM
                                          }
                                          getOptionLabel={(option) =>
                                            option?.nameTc
                                          }
                                          getOptionValue={(option) =>
                                            option?.id
                                          }
                                          apiParams={{
                                            unpaged: true,
                                          }}
                                          name={`medicalFollowUpItems.${index}.id`}
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <DeleteOutlinedIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          color="default"
                          variant="contained"
                          type="button"
                          size="small"
                          onClick={() => arrayHelpers.push("")}
                        >
                          新增
                        </Button>
                      </div>
                    )}
                  />
                </fieldset>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  label="交通工具"
                  name="transport"
                  apiUrl={"/api/transport"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) => option.nameTc}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormikTextField label="陪診員" name="escorterName" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  label="關係"
                  name="escorterRelationship"
                  apiUrl={"/api/escorterRelationship"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) => option.nameTc}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="備註" name="remarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item sm={3} xs={12}>
            <form noValidate>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Card variant="outlined" style={{ marginBottom: 15 }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                    院友基本資料
                                    </Typography>
                                    <Grid item sm={12} xs={12}>
                                        <table>
                                            <tr><td>姓名</td><td>{entity && (entity?.resident.lastnameTc + entity?.resident.firstnameTc)}</td></tr>
                                            <tr><td>狀態</td><td>{RESERVATION_STATUS_OPTIONS.find(elem => elem.value == infoEntity?.checkInStatus)?.label}</td></tr>
                                            <tr><td>床位</td><td>{entity?.resident?.bed?.bedNo}</td></tr>
                                        </table>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <fieldset style={{ textAlign: 'center' }}>
                                            <img src={entity && (entity.resident?.photoUrl)} />
                                        </fieldset>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <table>
                                            <tr><td>姓名 (中文)</td><td>{entity && (entity?.resident.lastnameTc + entity?.resident.firstnameTc)}</td></tr>
                                            <tr><td>姓名 (英文)</td><td>{entity && (entity?.resident?.lastname + " " + entity?.resident?.firstname)}</td></tr>
                                            <tr><td>性別</td><td>{GENDER_OPTIONS.find(elem => elem.value == entity?.resident?.gender)?.label}</td></tr>
                                            <tr><td>身份證號碼</td><td>{entity?.resident?.hkid}</td></tr>
                                            <tr><td>出生日期</td><td>{entity?.resident?.dateOfBirth}</td></tr>
                                            <tr><td>入住日期</td><td>{(entity?.resident?.checkInDate)?.substr(0, 10)}</td></tr>
                                            <tr><td>保證人姓名</td><td></td></tr>
                                            <tr><td>保證人關係</td><td></td></tr>
                                            <tr><td>保證人電話</td><td></td></tr>
                                        </table>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Grid> */}
    </Grid>
  );
};
