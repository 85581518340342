import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import { API_URL, ROUTES, FOLLOW_UP_STATUS_OPTION } from "../../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./LostHistoryRecord/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./LostHistoryRecord/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ListingPageHeader } from "./LostHistoryRecord/ListingPageHeader";
import { useParams } from "react-router-dom";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { NotificationActions } from "../../../actions";
import { EditDialog } from "./LostHistoryRecord/EditDialog";

export default function LostHistoryRecord() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  let { id } = useParams();
  const [editEntity, setEditEntity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [editOpen, setEditOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);
    //params.homeId = home?.home?.id;

    // params.institution = values.institution;
    // params.division = values.division;
    // params.specialist = values.specialist;
    // params.transport = values.transport;
    // params.medicalFollowUpItems = values.medicalFollowUpItems;
    // params.escorterRelationship = values.escorterRelationship;

    params.lostDateTime = values.lostDateTime;
    params.remarks = values.remarks;
    params.resident = id;

    return params;
  };

  // const createFilteringParams = (values, coreCreateFilteringParams) => {
  //   let params = {};
  //   params = coreCreateFilteringParams(values);

  //   params.home = home?.home?.id;

  //   if (params.dateFrom != null) {
  //     params.dateFrom = moment(values.dateFrom).format("YYYY-MM-DD");
  //   }

  //   if (params.dateTo != null) {
  //     params.dateTo = moment(values.dateTo).format("YYYY-MM-DD");
  //   }

  //   return params;
  // };

  // const normalizeReceivedValues = (values) => {
  //   values.institution = values.institution.id;
  //   values.specialist = values.specialist.id;

  //   return values;
  // };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 160,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton color="default" size="small" disabled={loading}>
                                <AssignmentIndOutlinedIcon />
                            </IconButton> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "createdAt",
        headerName: "記錄日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]?.substring(0, 10)
            : "--";
        },
      },
      {
        field: "lostDateTime",
        headerName: "遊走日期及時間",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return moment(params.row[params.field] + "Z").format(
            "YYYY-MM-DD HH:mm"
          );
        },
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 380,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  const onEdit = (values, formikActions) => {
    axios
      .put(API_URL.LOST_HISTORY_RECORD + "/" + values.id, {
        lostDateTime: values.lostDateTime,
        remarks: values.remarks,
        resident: id,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setEditEntity(null);
        formikActions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.LOST_HISTORY_RECORD + "?resident=" + id,
      getOne: API_URL.LOST_HISTORY_RECORD,
      create: API_URL.LOST_HISTORY_RECORD,
      edit: API_URL.LOST_HISTORY_RECORD,
      delete: API_URL.LOST_HISTORY_RECORD,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="遊走記錄"
      editableFields={[
        "lostDateTime",
        "remarks",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      // createFilteringParams={createFilteringParams}
      // normalizeReceivedValues={normalizeReceivedValues}
      // filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={
        {
          // handleFilterChange: handleFilterChange,
        }
      }
      maxWidth="md"
      defaultSorting={"updatedAt,desc"}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setLoading(false);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        // normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
