import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import {
  API_URL,
  RESIDENT_RESTRAINT_STATUS_OPTION,
  RESIDENT_RESTRAINT_ITEM_OPTION,
  RESIDENT_RESTRAINT_ITEM_REMARKS_OPTION,
} from "../../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./restraintItems/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./restraintItems/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { ListingPageHeader } from "./restraintItems/ListingPageHeader";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { useParams } from "react-router-dom";

export default function RestraintItemsPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  let { id } = useParams();

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;
    params.resident = id;
    params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    if (values.endDate) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }
    params.residentRestraintItemList = (
      params.residentRestraintItemList || []
    ).map((i) => {
      return {
        item: i.item,
        remarks: i.remarks,
        residentRestraintItemRemarks: (
          i.residentRestraintItemRemarks || []
        ).map((remark) => {
          return { remarks: remark.remarks };
        }),
        fullDay: i.fullDay || false,
        dayTime: i.dayTime || false,
        dayTimeStartTime: i.dayTimeStartTime,
        dayTimeEndTime: i.dayTimeEndTime,
        nightTime: i.nightTime || false,
        nightTimeStartTime: i.nightTimeStartTime,
        nightTimeEndTime: i.nightTimeEndTime,
        timePrn: i.timePrn || false,
      };
    });

    // params.residentRestraintItemList = [{
    //     item: params.residentRestraintItemList
    // }];

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }
    
    return params;
  };

  const normalizeReceivedValues = (values) => {
    //values.bed && (values.bed = values.bed?.id);
    //return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      onPrint,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="列印">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    onPrint(params.row);
                  }}
                  disabled={loading}
                >
                  <PrintOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "bed",
        headerName: "床位",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]?.bed?.bedNo;
        },
      },
      {
        field: "startDate",
        headerName: "開始日期",
        width: 90,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "endDate",
        headerName: "結束日期",
        width: 90,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "restraint",
        headerName: "約束衣",
        type: "boolean",
        width: 70,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? (
            <DoneIcon style={{ color: "green" }} />
          ) : (
            <CloseIcon style={{ color: "red" }} />
          );
        },
      },
      {
        field: "restraintBelt",
        headerName: "約束腰帶",
        type: "boolean",
        width: 80,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? (
            <DoneIcon style={{ color: "green" }} />
          ) : (
            <CloseIcon style={{ color: "red" }} />
          );
        },
      },
      {
        field: "restraintGloveRestraintMittens",
        headerName: "約束手套/連指手套",
        type: "boolean",
        width: 140,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? (
            <DoneIcon style={{ color: "green" }} />
          ) : (
            <CloseIcon style={{ color: "red" }} />
          );
        },
      },
      {
        field: "restraintWristBand",
        type: "boolean",
        headerName: "手腕帶",
        type: "boolean",
        width: 70,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? (
            <DoneIcon style={{ color: "green" }} />
          ) : (
            <CloseIcon style={{ color: "red" }} />
          );
        },
      },
      {
        field: "nonSlipPantsNonSlipBelt",
        type: "boolean",
        headerName: "防滑褲/防滑褲帶",
        type: "boolean",
        width: 135,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? (
            <DoneIcon style={{ color: "green" }} />
          ) : (
            <CloseIcon style={{ color: "red" }} />
          );
        },
      },
      {
        field: "platen",
        type: "boolean",
        headerName: "枱板",
        type: "boolean",
        width: 60,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? (
            <DoneIcon style={{ color: "green" }} />
          ) : (
            <CloseIcon style={{ color: "red" }} />
          );
        },
      },
      {
        field: "restraintProjectOther",
        type: "boolean",
        headerName: "其他",
        type: "boolean",
        width: 60,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? (
            <DoneIcon style={{ color: "green" }} />
          ) : (
            <CloseIcon style={{ color: "red" }} />
          );
        },
      },
      {
        field: "residentRestraintItemList",
        headerName: "詳細",
        width: 800,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <Table size="small">
                <TableBody>
                  {params.row[params.field]?.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {RESIDENT_RESTRAINT_ITEM_OPTION.find(
                            (elem) => elem.value == item.item
                          )?.label +
                            " - " +
                            item.residentRestraintItemRemarks?.map((remark) => {
                              return RESIDENT_RESTRAINT_ITEM_REMARKS_OPTION.find(
                                (elem) => elem.value == remark.remarks
                              )?.label;
                            }) +
                            (item.timePrn ? " - PRN" : "") +
                            (item.fullDay ? " - 全日" : "") +
                            (item.dayTime
                              ? " - 在日間由" +
                                item.dayTimeStartTime +
                                "至" +
                                item.dayTimeEndTime
                              : "") +
                            (item.nightTime
                              ? " - 在晚間由" +
                                item.nightTimeStartTime +
                                "至" +
                                item.nightTimeEndTime
                              : "")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          );
        },
      },
      {
        field: "status",
        headerName: "狀態",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESIDENT_RESTRAINT_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_RESTRAINT_ITEM + "?resident=" + id,
      getOne: API_URL.RESIDENT_RESTRAINT_ITEM,
      create: API_URL.RESIDENT_RESTRAINT_ITEM,
      edit: API_URL.RESIDENT_RESTRAINT_ITEM,
      delete: API_URL.RESIDENT_RESTRAINT_ITEM,
      export: API_URL.RESIDENT_RESTRAINT_ITEM + "/export?resident=" + id,
      print: "/api/residents/doc/restraintItem/print",
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="約束物品"
      editableFields={[
        "resident",
        "startDate",
        "endDate",
        "status",
        "residentRestraintItemList",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
      defaultPageSize={5}
      maxWidth="lg"
      rowHeight={300}
    ></EntityManagement>
  );
}
