import {
  API_URL,
  ROUTES,
  DOCUMENT_UPLOAD_STATUS_OPTIONS,
} from "../../../../constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../../components";
import {
  UrineBagMeasurementRecordsForm,
  initialValues,
  validationSchema,
} from "./forms/UrineBagMeasurementRecordsForm";
// import { MergeInputForm } from "./forms/MergeInputForm";
// import {
//   MergePrintForm,
//   mergePrintInitialValues,
//   mergePrintValidationSchema,
// } from "./forms/MergePrintForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "../documentUpload/FilteringForm";
import { PageHeader } from "../../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { useParams } from "react-router-dom";
import { BLOOD_SUGAR_SAMPLE_TIMES } from "../../../../constants/others.constant";
import { apiService } from "../../../../services";
import fileDownload from "js-file-download";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

const documentUploadPageHeader = (props) => {
  const { setCreating, clearNotification } = props;

  return (
    <PageHeader hideNotification>
      <Button
        style={{ marginRight: 15 }}
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlinedIcon />}
        onClick={() => {
          clearNotification();
          setCreating(true);
        }}
      >
        新增出入量表 (尿袋)
      </Button>
      {/* <Button
        style={{ marginRight: 15 }}
        variant="contained"
        color="primary"
        startIcon={<OpenInBrowserOutlinedIcon />}
        onClick={onExport}
      >
        匯出
      </Button>
      <Button
        style={{ marginRight: 15 }}
        variant="contained"
        color="primary"
        startIcon={<LibraryAddIcon />}
        onClick={() => {
          clearNotification();
          setMergeCreating(true);
        }}
      >
        合拼輸入
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PrintIcon />}
        onClick={() => {
          clearNotification();
          setMergePrinting(true);
        }}
      >
        合拼列印
      </Button> */}
    </PageHeader>
  );
};

export default function UrineBagMeasurementRecords(props) {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const { filteringParams, createFilteringParams } = props;
  let { id } = useParams();
  const [loadingDownload, setLoading] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.resident = id;
    params.residentDocumentType = "URINE_BAG_MEASUREMENT_RECORDS";

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;

    return values;
  };

  const onDownload = (values) => {
    apiService
      .get(values.documentPath, { responseType: "blob" })
      .then(function (response) {
        let data = response.data;
        setLoading(false);

        const fileName = values.documentPath.split("/").pop();
        fileDownload(data, "Urine_Bag_Measurement_Records_Form" + fileName);
      })
      .catch(function (response) {
        setLoading(false);
      });
  };

  const getTabColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="下載">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    onDownload(params.row);
                  }}
                  disabled={loadingDownload}
                >
                  <GetAppOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]?.lastnameTc +
            params.row[params.field]?.firstnameTc
          );
        },
      },
      {
        field: "updatedAt",
        headerName: "更新日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "approver",
        headerName: "批核人",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "residentDocumentStatus",
        headerName: "審查狀態",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return DOCUMENT_UPLOAD_STATUS_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll:
        API_URL.RESIDENT_DOCUMENT +
        "?resident=" +
        id +
        "&residentDocumentType=URINE_BAG_MEASUREMENT_RECORDS",
      getOne: API_URL.RESIDENT_DOCUMENT,
      create: API_URL.RESIDENT_DOCUMENT,
      edit: API_URL.RESIDENT_DOCUMENT,
      delete: API_URL.RESIDENT_DOCUMENT,
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="出入量表 (尿袋)"
      editableFields={[
        "resident",
        "documentPath",
        "residentDocumentStatus",
        "approver",
        "remarks",
      ]}
      getColumnSettings={getTabColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={UrineBagMeasurementRecordsForm}
      FilteringForm={FilteringForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      // mergePrintInitialValues={mergePrintInitialValues}
      // mergePrintValidationSchema={mergePrintValidationSchema}
      // mergeInputForm={MergeInputForm}
      // mergePrintForm={MergePrintForm}
      maxWidth="md"
      pageHeader={documentUploadPageHeader}
    />
  );
}
