import { useState, useEffect } from "react";
import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import FormikUploadField from "../../../components/inputs/FormikUploadField";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  API_URL,
  CHECK_IN_STATUS_OPTIONS,
  GENDER_OPTIONS,
  EVALUATION_RESULT_OPTION,
} from "../../../constants";
import { NotificationActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import { set } from "date-fns";

export const FloorPlanFormContent = (props) => {
  const { entity, onChange } = props;
  const {
    values,
    setFieldValue,
    handleChange,
    isSubmitting,
    handleSubmit,
    resetForm,
    initialValues,
  } = useFormikContext();
  const [guarantor, setGuarantor] = useState(null);

  useEffect(() => {
    resetForm({ values: entity });
    if (entity.contactList) {
      setGuarantor(entity.contactList.find((i) => i.guarantor));
    } else {
      setGuarantor(null);
    }
  }, [entity]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <fieldset>
            <FormLabel component="legend">平面圖</FormLabel>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikUploadField
                  required
                  label="floorPlanImgPath"
                  name="floorPlanImgPath"
                  policy="FLOOR_PLAN"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
    </form>
  );
};

export const FloorPlanForm = (props) => {
  const {
    initialValues = {},
    validationSchema,
    homeEntity = "",
    onDataChange,
    ...rest
  } = props;
  const [entity, setEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);
  let { id } = useParams();

  const createParams = (values) => {
    let params = {};

    params.name = entity.name;
    params.nameTc = entity.nameTc;
    params.address = entity.address;
    params.addressTc = entity.addressTc;
    params.tel = entity.tel;
    params.fax = entity.fax;
    params.website = entity.website;
    params.email = entity.email;
    params.license = entity.license;
    params.licenseExpiryDate = entity.licenseExpiryDate;
    params.brNo = entity.brNo;
    params.typeOfService = entity.typeOfService;
    params.servicePurposeAndGoals = entity.servicePurposeAndGoals;
    params.logo = entity.logo;
    params.floorPlanImgPath = values.floorPlanImgPath;
    params.dayNumHm = entity.dayNumHm;
    params.dayNumRnEn = entity.dayNumRnEn;
    params.dayNumHw = entity.dayNumHw;
    params.dayNumCw = entity.dayNumCw;
    params.dayNumAw = entity.dayNumAw;
    params.nightNumHw = entity.nightNumHw;
    params.nightNumCw = entity.nightNumCw;

    return params;
  };

  const handleSubmit = (values, actions) => {
    axios
      .put(API_URL.HOME + "/" + homeEntity.id, createParams(values))
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        onDataChange();
        actions.setSubmitting(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    if (homeEntity && homeEntity.id) {
      axios
        .get(API_URL.HOME + "/" + homeEntity.id)
        .then((response) => {
          setEntity(response.data);
          // setLoaded(true);
        })
        .catch((error) => {});
    }
  }, [homeEntity, notification]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => <FloorPlanFormContent entity={entity} onChange={handleSubmit} />}
    </Formik>
  );
};
