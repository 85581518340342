import { useState, useEffect, useLayoutEffect } from "react";
import moment from "moment";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useFormikContext, FieldArray } from "formik";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { RESIDENT_INVOICE_STATUS_OPTION } from "../../../constants";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import { FormikDialog } from "../../../components/dialogs";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import axios from "axios";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

export const initialValues = {
  invoiceId: "",
  resident: "",
  status: "",
  issueDateTime: moment(new Date()).toISOString(),
  dueDate: moment(new Date()).format("YYYY-MM-DD"),
  status: "NOT_YET_PAID",
  residentInvoiceItems: [],
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  issueDateTime: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  dueDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  residentInvoiceItemList: Yup.array().of(
    Yup.object().shape({
      invoiceItem: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      // price: Yup.number()
      //   .positive(<FormattedMessage id="validation.message.positiveNumber" />)
      //   .required(<FormattedMessage id="validation.message.required" />),
      qty: Yup.string()
        .matches(/^[1-9][0-9]*$/, "輸入必須為正整數")
        .required(<FormattedMessage id="validation.message.required" />),
      price: Yup.number()
        .min(0, "輸入必須為0或正數")
        .required(<FormattedMessage id="validation.message.required" />),
    })
  ),
});

export function EditDialog(props) {
  const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"修改付款狀態 (" + entityValues?.invoiceId + ")"}
      onSubmit={onSubmit}
      onClose={onClose}
      validationSchema={validationSchema}
      maxWidth="md"
    >
      <EditForm entity={entityValues} />
    </FormikDialog>
  );
}

const EditForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity } = props;
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  useEffect(() => {
    // Fix 收費項目 not update on first edit after update
    resetForm({ values: values });
    // end of Fix 收費項目 not update on first edit after update

    // convert resident field from object type to string id (because reference field return object which is not wanted)
    if (!values || !values.resident?.id) return;

    values.resident = values.resident?.id || values.resident;
    // end of convert resident field from object type to string id
  }, [values]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={3} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    disabled
                    required
                    label="院友姓名"
                    name="resident"
                    apiUrl={
                      "/api/homes/" + home.home.id + "/residents/references"
                    }
                    getOptionLabel={(option) =>
                      option.lastnameTc + option.firstnameTc
                    }
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormikSelectField
                    required
                    disabled={entity.residentInvoicePaymentList ? true : false}
                    label="付款狀態"
                    name="status"
                    options={RESIDENT_INVOICE_STATUS_OPTION}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormikDateTimeField
                    disabled={entity.residentInvoicePaymentList ? true : false}
                    required
                    label="發票日期及時間"
                    name="issueDateTime"
                  />
                </Grid>

                <Grid item sm={3} xs={12}>
                  <FormikDateField
                    disabled={entity.residentInvoicePaymentList ? true : false}
                    required
                    label="到期日"
                    name="dueDate"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12}>
          <fieldset>
            <FormLabel component="legend">收費項目資料</FormLabel>
            <FieldArray
              name="residentInvoiceItemList"
              render={(arrayHelpers) => (
                <div>
                  <Table
                    stickyHeader
                    padding="none"
                    style={{ marginBottom: 15 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          omponent="th"
                          style={{ padding: 10, minWidth: 250 }}
                        >
                          收費項目
                        </TableCell>
                        <TableCell
                          omponent="th"
                          style={{ padding: 10, minWidth: 250 }}
                        >
                          描述
                        </TableCell>
                        <TableCell
                          omponent="th"
                          style={{ padding: 10, minWidth: 100 }}
                        >
                          數量
                        </TableCell>
                        <TableCell
                          omponent="th"
                          style={{ padding: 10, minWidth: 100 }}
                        >
                          單價
                        </TableCell>
                        <TableCell
                          align="center"
                          omponent="th"
                          style={{ padding: 10, minWidth: 100 }}
                        >
                          總數
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: 50, minWidth: 50 }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.residentInvoiceItemList &&
                        values.residentInvoiceItemList.map((item, index) => (
                          <TableRow key={index} disablegutters>
                            <TableCell style={{ paddingRight: 1 }}>
                              <FormikReferenceField
                                edit={entity ? true : false}
                                disabled={
                                  entity.residentInvoicePaymentList
                                    ? true
                                    : false
                                }
                                required
                                styles={{ marginTop: "-4px" }}
                                label="收費項目"
                                apiUrl={"/api/invoiceItems?type=INVOICE"}
                                getOptionLabel={(option) => option?.nameTc}
                                getOptionValue={(option) => option?.id}
                                name={`residentInvoiceItemList.${index}.invoiceItem`}
                                triggerUpdateOnChangeOf={item.invoiceItem}
                                apiParams={{
                                  unpaged: true,
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ paddingRight: 1 }}>
                              <FormikTextField
                                disabled={
                                  entity.residentInvoicePaymentList
                                    ? true
                                    : false
                                }
                                label="描述"
                                name={`residentInvoiceItemList.${index}.description`}
                              />
                            </TableCell>
                            <TableCell style={{ paddingRight: 1 }}>
                              <FormikTextField
                                disabled={
                                  entity.residentInvoicePaymentList
                                    ? true
                                    : false
                                }
                                required
                                type="number"
                                label="數量"
                                name={`residentInvoiceItemList.${index}.qty`}
                                defaultValue="0"
                              />
                            </TableCell>
                            <TableCell style={{ paddingRight: 10 }}>
                              <FormikTextField
                                disabled={
                                  entity.residentInvoicePaymentList
                                    ? true
                                    : false
                                }
                                required
                                type="number"
                                label="單價"
                                name={`residentInvoiceItemList.${index}.price`}
                                defaultValue="0"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              {values.residentInvoiceItemList[index].qty &&
                              values.residentInvoiceItemList[index].price
                                ? "$ " +
                                  Number(
                                    (
                                      values.residentInvoiceItemList[index]
                                        .qty *
                                      values.residentInvoiceItemList[index]
                                        .price
                                    ).toFixed(2)
                                  )
                                : "$ " + 0}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                disabled={
                                  entity.residentInvoicePaymentList
                                    ? true
                                    : false
                                }
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Button
                    disabled={entity.residentInvoicePaymentList ? true : false}
                    color="default"
                    variant="contained"
                    type="button"
                    size="small"
                    onClick={() => arrayHelpers.push("")}
                  >
                    新增
                  </Button>
                  <Typography variant="h4" component="h2" align="right">
                    {values &&
                      "賬單總數: $ " +
                        Number(
                          (
                            values?.residentInvoiceItemList &&
                            values.residentInvoiceItemList.map((item) =>
                              item.qty && item.price ? item.qty * item.price : 0
                            )
                          )
                            ?.reduce((a, b) => a + b, 0)
                            .toFixed(2)
                        )}
                  </Typography>
                </div>
              )}
            />
          </fieldset>
        </Grid>
      </Grid>
    </form>
  );
};
