import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { PersonalDataForm } from './components/PersonalDataForm';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Notification } from '../../components';
import { PhotoForm } from './components/PhotoForm';
import { NotificationActions } from '../../actions';
import { WelfareForm } from './components/WelfareForm';
import { HealthForm } from './components/HealthForm';
import { ContactForm } from './components/ContactForm';
import MedicalRecord from './components/MedicalRecord';
import Medicine from './components/Medicine';
import LostHistoryRecord from './components/LostHistoryRecord';
import MealRecord from "./components/MealRecord";
import StoolRecord from "./components/StoolRecord";
import VitalSigns from './components/VitalSigns';
import FollowUp from './components/FollowUp';
import TreatmentItems from './components/TreatmentItems';
import EmergencyRecord from './components/EmergencyRecord';
import RestraintItems from './components/RestraintItems';
import Wound from './components/Wound';
import Document from './components/Document';
import DocumentUpload from "./components/DocumentUpload";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            <Card>
                <CardContent>
                    {value === index && children}
                </CardContent>
            </Card >
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }));


function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const tabs = [
  { title: "個人資料", component: <PersonalDataForm /> },
  { title: "健康評估", component: <HealthForm /> },
  { title: "福利資料", component: <WelfareForm /> },
  { title: "親友", component: <ContactForm /> },
  { title: "院友病歷", component: <MedicalRecord /> },
  { title: "藥物", component: <Medicine /> },
  { title: "遊走記錄", component: <LostHistoryRecord /> },
  { title: "膳食記錄", component: <MealRecord /> },
  { title: "大便記錄", component: <StoolRecord /> },
  { title: "生命表徵", component: <VitalSigns /> },
  { title: "院友覆診", component: <FollowUp /> },
  { title: "醫療項目", component: <TreatmentItems /> },
  { title: "急症記錄", component: <EmergencyRecord /> },
  { title: "約束物品", component: <RestraintItems /> },
  { title: "傷口護理", component: <Wound /> },
  // { title: '個人事項', disabled: true, component: null },
  { title: "文件上傳", component: <DocumentUpload /> },
  { title: "文件", component: <Document /> },
];

export default function ResidentManagementPage() {
    const classes = useStyles();
    const [tabActiveValue, setTabActiveValue] = useState(0);
    const dispatch = useDispatch();

    const handleTabChange = (event, newValue) => {
        setTabActiveValue(newValue);
        dispatch(NotificationActions.clear());
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default" style={{ marginBottom: 15 }}>
                <Tabs
                    value={tabActiveValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {
                        tabs && tabs.map((tab, index) => {
                            return <Tab key={index} label={tab.title} disabled={tab.disabled} {...a11yProps(index)} />
                        })
                    }
                </Tabs>
            </AppBar>

            <Notification />

            <Grid container spacing={2}>
                <Grid item lg={9} md={12} xs={12}>
                    {
                        tabs && tabs.map((tab, index) => {
                            return (
                                <TabPanel key={index} value={tabActiveValue} index={index}>
                                    {tab.component || <h3>{tab.title}</h3>}
                                </TabPanel>
                            );
                        })
                    }
                </Grid>
                <Grid item lg={3} md={12} xs={12}>
                    <Card>
                        <CardContent>
                            <PhotoForm />
                        </CardContent>
                    </Card >
                </Grid>
            </Grid>
        </div>
    );
}