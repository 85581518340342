import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FilteringForm, initialFilteringValues, filteringValidationSchema } from './FilteringForm';
import { PageHeader } from '../../../../components/PageHeader';
import { Formik } from "formik";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import RecentSituation from "../documentUploadTabs/RecentSituation";
import PersonalCarePlan from "../documentUploadTabs/PersonalCarePlan";
import InsulinInjectionRecord from "../documentUploadTabs/InsulinInjectionRecord";
import SendToHospital from "../documentUploadTabs/SendToHospital";
import IndividualMedicineRecords from "../documentUploadTabs/IndividualMedicineRecords";
import BodyCheckReport from "../documentUploadTabs/BodyCheckReport";
import NasogastricTubeMeasurements from "../documentUploadTabs/NasogastricTubeMeasurements";
import UrineBagMeasurementRecords from "../documentUploadTabs/UrineBagMeasurementRecords";
import TurningRecords from "../documentUploadTabs/TurningRecords";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { NotificationActions } from '../../../../actions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Card>
                <CardContent>
                    {value === index && children}
                </CardContent>
            </Card >
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const tabs = [
  { title: "院友近況", component: RecentSituation },
  { title: "個人照顧計劃", component: PersonalCarePlan },
  { title: "胰島素注射記錄", component: InsulinInjectionRecord },
  { title: "送院記錄", component: SendToHospital },
  { title: "個人藥物記錄", component: IndividualMedicineRecords },
  { title: "住客體格檢驗報告", component: BodyCheckReport },
  { title: "出入量表 (胃喉)", component: NasogastricTubeMeasurements },
  { title: "出入量表 (尿袋)", component: UrineBagMeasurementRecords },
  { title: "轉身記錄", component: TurningRecords },
];


export const ListingPageHeader = (props) => {
    const home = useSelector(state => state.home);
    const [tabActiveValue, setTabActiveValue] = useState(0);
    const dispatch = useDispatch();

    const handleTabChange = (event, newValue) => {
        setTabActiveValue(newValue);
        dispatch(NotificationActions.clear());
    };

    const {
        filteringParams,
        handleFilterChange,
        createFilteringParams
    } = props;

    return (
      <div>
        <PageHeader hideNotification>
          <h1>文件上傳</h1>
          {/* <Formik
                    initialValues={initialFilteringValues}
                    validationSchema={filteringValidationSchema}
                >
                    {() => (
                        <Fragment>
                            <FilteringForm
                                handleFilterChange={handleFilterChange}
                            />
                        </Fragment>
                    )}
                </Formik> */}
        </PageHeader>
        <AppBar position="static" color="default" style={{ marginBottom: 15 }}>
          <Tabs
            value={tabActiveValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs &&
              tabs.map((tab, index) => {
                return (
                  <Tab
                    key={index}
                    label={tab.title}
                    disabled={tab.disabled}
                    {...a11yProps(index)}
                  />
                );
              })}
          </Tabs>
        </AppBar>

        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            {tabs &&
              tabs.map((tab, index) => {
                const Component = tab.component;

                return (
                  <TabPanel key={index} value={tabActiveValue} index={index}>
                    {(
                      <Component
                        filteringParams={filteringParams}
                        createFilteringParams={createFilteringParams}
                      />
                    ) || <h3>{tab.title}</h3>}
                  </TabPanel>
                );
              })}
          </Grid>
        </Grid>
      </div>
    );
}