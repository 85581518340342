import * as Yup from "yup";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikTextAreaField from "../../../components/inputs/FormikTextAreaField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikUploadField from "../../../components/inputs/FormikUploadField";
import { FloorPlanForm, Test } from "./FloorPlanForm";
import { Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import axios from "axios";
import {
  API_URL,
  CHECK_IN_STATUS_OPTIONS,
  GENDER_OPTIONS,
  EVALUATION_RESULT_OPTION,
} from "../../../constants";

export const initialValues = {
  name: "",
  nameTc: "",
  address: "",
  addressTc: "",
  tel: "",
  fax: "",
  website: "",
  email: "",
  license: "",
  licenseExpiryDate: "",
  brNo: "",
  typeOfService: "",
  servicePurposeAndGoals: "",
  dayNumHm: "",
  dayNumRnEn: "",
  dayNumHw: "",
  dayNumCw: "",
  dayNumAw: "",
  nightNumHw: "",
  nightNumCw: "",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  nameTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  address: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  addressTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  tel: Yup.string()
    .min(8, "電話長度必須為8個位")
    .max(8, "電話長度必須為8個位")
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  fax: Yup.string().nullable(),
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "網址格式錯誤"
    )
    .nullable(),
  email: Yup.string()
    .email(<FormattedMessage id="validation.message.invalidEmailFormat" />)
    .nullable(),
  license: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  licenseExpiryDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  brNo: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dayNumHm: Yup.string()
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dayNumRnEn: Yup.string()
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dayNumHw: Yup.string()
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dayNumCw: Yup.string()
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dayNumAw: Yup.string()
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  nightNumHw: Yup.string()
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  nightNumCw: Yup.string()
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export const DataInputForm = (props) => {
  const { entity, ...rest } = props;
  const [loaded, setLoaded] = useState(false);
  const { initialValues, values, resetForm } = useFormikContext();

  const handleDataChange = () => {
    if (entity && entity.id) {
      axios
        .get(API_URL.HOME + "/" + entity.id)
        .then((response) => {
          console.log(response.data.floorPlanImgPath);
          if (response.data.floorPlanImgPath === null) {
            values.floorPlanImgPath = null;
          } else {
            values.floorPlanImgPath = response.data.floorPlanImgPath;
          }
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={9} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              院舍資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikTextField required label="院舍名稱(英文)" name="name" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  label="院舍名稱(中文)"
                  name="nameTc"
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="地址(英文)" name="address" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="地址(中文)" name="addressTc" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField required label="電話" name="tel" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="傳真" name="fax" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="網站" name="website" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="電郵" name="email" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  label="安老院牌照號碼"
                  name="license"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateField
                  required
                  label="牌照有效期至"
                  name="licenseExpiryDate"
                  startTime
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField required label="商業登記號碼" name="brNo" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="院舍性質" name="typeOfService" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextAreaField
                  label="服務宗旨及目標"
                  name="servicePurposeAndGoals"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikUploadField
                  required
                  label="Logo"
                  name="logo"
                  policy="HOME_LOGO"
                />
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12}>
              <FloorPlanForm
                homeEntity={entity}
                onDataChange={handleDataChange}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              職位人數
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="日更HM" name="dayNumHm" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="日更RN/EN" name="dayNumRnEn" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="日更HW" name="dayNumHw" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="日更CW" name="dayNumCw" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="日更AW" name="dayNumAw" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="夜更HW" name="nightNumHw" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="夜更CW" name="nightNumCw" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
