import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import {
  API_URL,
  ROUTES,
  STAFF_STATUS_OPTION,
  GENDER_OPTIONS,
} from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { DetailsDialog } from "./components/DetailsDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";

export default function StaffUsersPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [detailsEntity, setDetailsEntity] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );

  const createParams = (values, coreCreateParams) => {

    let params = {};
    params = coreCreateParams(values);

    if (values.hkid === "_______(_)") {
      params.hkid = null;
    }

    params.entryDate = moment(values.entryDate).format("YYYY-MM-DD");

    if (values.resignationDate) {
      params.resignationDate = moment(values.resignationDate).format(
        "YYYY-MM-DD"
      );
    }

    params.workHomes = (params.workHomes || []).map((i) => {
      return {
        id: i.id,
        home: i.home,
        roster: i.roster,
        position: i.position,
        workingStartTime: moment(i.workingStartTime).format("HH:mm"),
        workingEndTime: moment(i.workingEndTime).format("HH:mm"),
      };
    });

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    //params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.workHomes.map((item) => {
      item.home = item.home.id;
      item.workingStartTime =
        moment().format("YYYY-MM-DD") + "T" + item.workingStartTime + "+08:00";
      item.workingEndTime =
        moment().format("YYYY-MM-DD") + "T" + item.workingEndTime + "+08:00";
    });
    values.role = values.role.id;

    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="查看資料">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDetailsEntity(params);
                    setDetailsOpen(true);
                  }}
                  disabled={loading}
                >
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "username",
        headerName: "用戶名稱",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "員工姓名 (中文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "name",
        headerName: "員工姓名 (英文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "hkid",
        headerName: "身份證號碼",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "gender",
        headerName: "性別",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return GENDER_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "entryDate",
        headerName: "入職日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "phone",
        headerName: "聯絡電話",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "status",
        headerName: "員工狀態",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return STAFF_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.STAFF_USERS,
      getOne: API_URL.STAFF_USERS,
      create: API_URL.STAFF_USERS,
      edit: API_URL.STAFF_USERS,
      delete: API_URL.STAFF_USERS,
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="員工"
      editableFields={[
        "username",
        "password",
        "staffNumber",
        "role",
        "name",
        "nameTc",
        "entryDate",
        "resignationDate",
        "hkid",
        "gender",
        "phone",
        "status",
        "workHomes",
        "vaccinationUrl",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
      maxWidth="lg"
    >
      <DetailsDialog
        open={detailsOpen}
        entity={detailsEntity}
        onClose={() => {
          setDetailsEntity(null);
          setDetailsOpen(false);
        }}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
