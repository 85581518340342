import moment from "moment";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import fileDownload from "js-file-download";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Document() {
  let { id } = useParams();
  const classes = useStyles();

  const onPrintMedicalExaminationForm = (values, formikActions) => {
    axios
      .get("/api/residents/doc/medicalExaminationForm/print/" + id, {
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") +
            "_安老院住客體格檢驗報告書.docx"
        );
      });
  };

  const onPrintPersonalInfo = (values, formikActions) => {
    axios
      .get("/api/residents/doc/personalInfo/print/" + id, {
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_院友個人資料紀錄表.docx"
        );
      });
  };

  const onPrintPersonalMemo = (values, formikActions) => {
    axios
      .get("/api/residents/doc/personalMemo/print/" + id, {
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_院友備忘.docx"
        );
      });
  };

  const onPrintConsentLetter = (values, formikActions) => {
    axios
      .get(
        "/api/residents/doc/usageOfRestrictedItemEvalAndConsentLetter/print/" +
          id,
        {
          responseType: "blob",
        }
      )
      .then(function (response) {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") +
            "_使用約束物品評估及同意書.docx"
        );
      });
  };

  const downloadDoc = (filename) => {
    console.log("clicked");
    axios
      .get("/api/residents/doc/downloadDoc?filename=" + filename, {
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_" + filename
        );
      });
  };

  return (
    <div>
      <Typography variant="h2" gutterBottom>
        文件下載
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell width="100">動作</TableCell>
              <TableCell>報告名稱</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={onPrintMedicalExaminationForm}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>安老院住客體格檢驗報告書.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={onPrintPersonalInfo}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>院友個人資料紀錄表.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={onPrintPersonalMemo}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>院友備忘.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={onPrintConsentLetter}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>使用約束物品評估及同意書.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => downloadDoc("院友近況.docx")}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>院友近況.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => downloadDoc("（尿袋）出入量表.docx")}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>（尿袋）出入量表.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => downloadDoc("（胃喉）出入量表.docx")}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>（胃喉）出入量表.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => downloadDoc("個人藥物記錄.docx")}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>個人藥物記錄.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() =>
                      downloadDoc("安老院舍住客個人照顧計劃ICP.docx")
                    }
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>安老院舍住客個人照顧計劃ICP.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => downloadDoc("轉身表.docx")}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>轉身表.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => downloadDoc("胰島素注射記錄表.docx")}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>胰島素注射記錄表.docx</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => downloadDoc("院友送院資料.docx")}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>院友送院資料.docx</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
