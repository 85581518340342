import { useState, useEffect } from "react";
import { EntityManagement } from "../../components";
import { API_URL } from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import { PageHeader } from "../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Image from "../../components/Image";
import moment from "moment";

const getColumnSettings = (props) => {
  const {
    setEditingEntity,
    setDeletingEntity,
    clearNotification,
    loading,
    homeStat,
  } = props;

  return [
    {
      field: "action",
      headerName: "動作",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: function (params) {
        return (
          <div>
            <Tooltip title="修改">
              <IconButton
                color="default"
                size="small"
                onClick={() => {
                  clearNotification();
                  setEditingEntity(params.row);
                }}
                disabled={loading}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="刪除">
              <IconButton
                color="default"
                size="small"
                onClick={() => {
                  clearNotification();
                  setDeletingEntity(params.row);
                }}
                disabled={loading}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "logo",
      headerName: "院舍標誌",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return params.row[params.field] ? (
          <Image
            url={params.row[params.field]}
            style={{ maxWidth: 48, maxHeight: 32 }}
          />
        ) : (
          "--"
        );
      },
    },
    {
      field: "nameTc",
      headerName: "院舍名稱(中文)",
      width: 500,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: "count",
      headerName: "入住人數 / 總床位",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: function (params) {
        return homeStat
          .filter((item) => item.id == params.row["id"])
          .map((row) => {
            return row.registeredResidentCount + " / " + row.homeBedCount;
          });
      },
    },
    {
      field: "license",
      headerName: "安老院牌照號碼",
      width: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "licenseExpiryDate",
      headerName: "牌照有效期至",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: function (params) {
        return params.row[params.field]?.substring(0, 10);
      },
    },
    {
      field: "brNo",
      headerName: "商業登記號碼",
      width: 150,
      sortable: false,
      filterable: false,
    },
  ];
};
const HomeManagementPageHeader = (props) => {
  const { setCreating, clearNotification } = props;

  return (
    <PageHeader title="院舍管理">
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlinedIcon />}
        onClick={() => {
          clearNotification();
          setCreating(true);
        }}
      >
        {/*<FormattedMessage id="entity.create" values={{ entityModel: entityModel }} />*/}
        新增院舍
      </Button>
    </PageHeader>
  );
};

export default function HomeManagementPage() {
  const [entity, setEntity] = useState(null);
  const [stat, setStat] = useState([]);

  useEffect(() => {
    axios.get("/api/homes/extraStat").then((response) => {
      let data = response.data;
      setStat(data);
    });
  }, []);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.logo = values.logo;
    params.floorPlanImgPath = values.floorPlanImgPath;
    params.dayNumHm = values.dayNumHm.toString();
    params.dayNumRnEn = values.dayNumRnEn.toString();
    params.dayNumHw = values.dayNumHw.toString();
    params.dayNumCw = values.dayNumCw.toString();
    params.dayNumAw = values.dayNumAw.toString();
    params.nightNumHw = values.nightNumHw.toString();
    params.nightNumCw = values.nightNumCw.toString();

    params.licenseExpiryDate = moment(values.licenseExpiryDate).format(
      "YYYY-MM-DD"
    );

    /*if (params.licenseExpiryDate) {
            if (typeof params.licenseExpiryDate === "string") {
                params.licenseExpiryDate = params.licenseExpiryDate.substring(0, 10);
            } else {
                params.licenseExpiryDate = format(params.licenseExpiryDate, "yyyy-MM-dd");
            }
        }*/

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.dayNumHm = values.dayNumHm.toString();
    values.dayNumRnEn = values.dayNumRnEn.toString();
    values.dayNumHw = values.dayNumHw.toString();
    values.dayNumCw = values.dayNumCw.toString();
    values.dayNumAw = values.dayNumAw.toString();
    values.nightNumHw = values.nightNumHw.toString();
    values.nightNumCw = values.nightNumCw.toString();

    return values;
  };

  return (
    <EntityManagement
      entityModel="院舍"
      editableFields={[
        "name",
        "nameTc",
        "address",
        "addressTc",
        "tel",
        "fax",
        "website",
        "email",
        "license",
        "licenseExpiryDate",
        "brNo",
        "typeOfService",
        "servicePurposeAndGoals",
        "logo",
        "floorPlanImgPath",
        "dayNumHm",
        "dayNumRnEn",
        "dayNumHw",
        "dayNumCw",
        "dayNumAw",
        "nightNumHw",
        "nightNumCw",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      apiUrl={{
        getAll: API_URL.HOME,
        getOne: API_URL.HOME,
        create: API_URL.HOME,
        edit: API_URL.HOME,
        delete: API_URL.HOME,
      }} // Or aipUrl={API_URL.HOME}
      entity={entity}
      setEntity={setEntity}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={HomeManagementPageHeader}
      normalizeReceivedValues={normalizeReceivedValues}
      homeStat={stat}
    ></EntityManagement>
  );
}
