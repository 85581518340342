export const CALENDAR_FONT_COLOR_OPTION = [
  { value: "#000000", label: "黑色" },
  { value: "#ffffff", label: "白色" },
];

export const NIGHT_STAFF_POSITION_OPTION = [
  { value: "HW", label: "HW" },
  { value: "CW", label: "CW" },
];

export const STAFF_POSITION_OPTION = [
  { value: "HM", label: "HM" },
  { value: "RNEN", label: "RN/EN" },
  { value: "HW", label: "HW" },
  { value: "CW", label: "CW" },
  { value: "AW", label: "AW" },
];

export const STAFF_TAG_OPTION = [
  { value: "✓", label: "✓" },
  { value: "P", label: "P" },
  { value: "N", label: "N" },
];

export const STAFF_ROSTER_OPTION = [
  { value: "DAY", label: "日更" },
  { value: "NIGHT", label: "夜更" },
  { value: "DAY_SUBSTITUTE", label: "日更替假" },
  { value: "NIGHT_SUBSTITUTE", label: "夜更替假" },
];

export const STAFF_STATUS_OPTION = [
  { value: "UNEMPLOYED", label: "未入職" },
  { value: "ON_THE_JOB", label: "在職中" },
  { value: "RESIGNED", label: "已離職" },
];

export const RESIDENT_ICP_STATUS_OPTIONS = [
  { value: "IN_DRAFT", label: "制定中" },
  { value: "SUBMITTED", label: "已遞交" },
];

export const RETURN_TRANSACTION_METHOD_OPTION = [
  { value: "CHECK", label: "支票" },
  { value: "PAID_IN_HOME_EXPENSES", label: "院費內扣回" },
];

export const HOME_EXPENSE_STATUS_OPTION = [
  { value: "NOT_YET_PAID", label: "未付款" },
  { value: "PAID", label: "已付款" },
  { value: "VOIDED", label: "已撤單" },
];

export const DOWN_PAYMENT_METHOD_OPTION = [
  { value: "CASH", label: "現金" },
  { value: "CHECK", label: "支票" },
  { value: "TRANSFER", label: "銀行轉帳" },
  { value: "CREDIT_CARD", label: "信用卡" },
  { value: "ALIPAY", label: "支付寶" },
  { value: "PAY_ME", label: "PayMe" },
  { value: "ONLINE_PAYMENT", label: "網上付款" },
  { value: "WECHAT_PAY", label: "WeChat Pay" },
];

export const PAYMENT_RECORD_OPTION = [
  { value: "CASH", label: "現金" },
  { value: "CHECK", label: "支票" },
  { value: "TRANSFER", label: "銀行轉帳" },
  { value: "CREDIT_CARD", label: "信用卡" },
  { value: "ALIPAY", label: "支付寶" },
  { value: "PAY_ME", label: "PayMe" },
  { value: "ONLINE_PAYMENT", label: "網上付款" },
  { value: "PAID_IN_HOME_EXPENSES", label: "院費內扣回" },
  { value: "WECHAT_PAY", label: "WeChat Pay" },
  { value: "PETTY_CASH", label: "零用現金" },
];

export const EXPENSE_TRANSACTION_METHOD_OPTION = [
  { value: "CASH", label: "現金" },
  { value: "CHECK", label: "支票" },
  { value: "TRANSFER", label: "銀行轉帳" },
  { value: "CREDIT_CARD", label: "信用卡" },
  { value: "ALIPAY", label: "支付寶" },
  { value: "PAY_ME", label: "PayMe" },
  { value: "ONLINE_PAYMENT", label: "網上付款" },
  { value: "WECHAT_PAY", label: "WeChat Pay" },
  { value: "PETTY_CASH", label: "零用現金" },
];

export const RESIDENT_INVOICE_PAYMENT_METHOD_OPTION = [
  { value: "CASH", label: "現金" },
  { value: "CHECK", label: "支票" },
  { value: "TRANSFER", label: "銀行轉帳" },
  { value: "CREDIT_CARD", label: "信用卡" },
  { value: "ALIPAY", label: "支付寶" },
  { value: "PAY_ME", label: "PayMe" },
  { value: "ONLINE_PAYMENT", label: "網上付款" },
  { value: "WECHAT_PAY", label: "WeChat Pay" },
];

export const RESIDENT_PAYMENT_STATUS_OPTION = [
  { value: "NOT_YET_PAID", label: "未付完" },
  { value: "PAID", label: "已付全款" },
];

export const RESIDENT_DEPOSIT_STATUS_OPTION = [
  { value: "NOT_YET_PAID", label: "未付款" },
  { value: "PAID", label: "已付款" },
  { value: "VOIDED", label: "已撤單" },
  { value: "RETURNED_DEPOSIT", label: "已退還按金" },
  { value: "RETURNED_DOWN", label: "已退還訂金" },
  { value: "NOT_YET_RETURNED_DEPOSIT", label: "尚有按金未退" },
];

export const RESIDENT_INVOICE_STATUS_OPTION = [
  { value: "NOT_YET_PAID", label: "未付款" },
  { value: "PAID", label: "已付款" },
  { value: "VOIDED", label: "已撤單" },
];

export const RESIDENT_RESTRAINT_ITEM_REMARKS_OPTION = [
  { value: "SIT_ON_CHAIR", label: "坐在椅子上" },
  { value: "LAY_ON_BED", label: "躺在床上" },
  { value: "SIT_ON_WHEELCHAIR", label: "坐在輪椅上" },
  { value: "LAY_ON_BED_SIT_ON_WHEELCHAIR", label: "坐在椅上及躺在床上" },
];

export const RESIDENT_RESTRAINT_STATUS_OPTION = [
  { value: "FAMILY_VERBALLY_APPROVE", label: "親屬口頭同意" },
  { value: "VAILD", label: "有效" },
  { value: "COMPLETED", label: "已完成" },
  { value: "CANCELLED", label: "已取消" },
];

export const RESIDENT_RESTRAINT_ITEM_OPTION = [
  { value: "RESTRAINT", label: "約束衣" },
  { value: "RESTRAINT_BELT", label: "約束腰帶" },
  { value: "RESTRAINT_GLOVE_RESTRAINT_MITTENS", label: "手套/連指手套" },
  { value: "RESTRAINT_WRIST_BAND", label: "手腕帶" },
  { value: "NON_SLIP_PANTS_NON_SLIP_BELT", label: "防滑褲/防滑褲帶" },
  { value: "PLATEN", label: "枱板" },
  { value: "OTHERS", label: "其他" },
];

export const RESIDENT_WASHING_WOUND_EXUDATE_AMOUNT_OPTION = [
  { value: "NONE", label: "無" },
  { value: "SMALL", label: "少" },
  { value: "INTERMEDIATE", label: "中" },
  { value: "LARGE", label: "多" },
];

export const RESIDENT_WASHING_WOUND_SMELL_OPTION = [
  { value: "NONE", label: "無" },
  { value: "PECULIAR_ODUR", label: "異味" },
  { value: "RANCID", label: "腐臭" },
];

export const RESIDENT_WASHING_WOUND_EXUDATE_TYPE_OPTION = [
  { value: "NONE", label: "無" },
  { value: "SEROUS", label: "漿液性" },
  { value: "FESTERING", label: "化濃性" },
  { value: "HEMOLYSIS", label: "溶血性" },
];

export const RESIDENT_WASHING_WOUND_COLOR_OPTION = [
  { value: "BLACK", label: "黑色" },
  { value: "GREY", label: "灰色" },
  { value: "BROWN", label: "棕色" },
  { value: "GREENISH_YELLOW", label: "綠黃色" },
  { value: "GREEN", label: "綠色" },
  { value: "YELLOW", label: "黃色" },
  { value: "PINK", label: "粉紅色" },
  { value: "MILKY_WHITE", label: "奶白色" },
];

export const RESIDENT_WASHING_WOUND_LEVEL_OPTION = [
  { value: "LEVEL_ONE", label: "一級 - 受壓徵狀，皮膚受壓紅" },
  { value: "LEVEL_TWO", label: "二級 - 皮膚已破損，撕裂，水泡" },
  {
    value: "LEVEL_THREE",
    label: "三級 - 表皮真皮已失去，已見肌肉組織，同時／或有糜爛，滲液",
  },
  { value: "LEVEL_FOUR", label: "四級 - 深入深層組織，如結締組織，骨骼" },
  {
    value: "CANNOT_DECIDE",
    label: "無法分級 - 潰瘍完全被腐肉和／或痂皮所覆蓋",
  },
  {
    value: "SUSPECTED_DEEP_TISSUE_INJURY",
    label:
      "疑似深層組織損傷 - 局部完整性皮膚出現紫色或暗褐色等皮膚顏色的改變，或出現血性",
  },
];

export const RESIDENT_WOUND_POSITION_FRONT_OPTION = [
  { value: "LEFT_EAR", label: "左耳" },
  { value: "RIGHT_EAR", label: "右耳" },
  { value: "LEFT_SHOULDER", label: "左肩" },
  { value: "RIGHT_SHOULDER", label: "右肩" },
  { value: "FRONT_CHEST", label: "前胸" },
  { value: "LEFT_FRONT_ABDOMEN", label: "左前腹" },
  { value: "RIGHT_FRONT_ABDOMEN", label: "右前腹" },
  { value: "LEFT_PLAM", label: "左手掌" },
  { value: "RIGHT_PLAM", label: "右手掌" },
  { value: "LEFT_WRIST", label: "左手腕" },
  { value: "RIGHT_WRIST", label: "右手腕" },
  { value: "LEFT_UPPER_THIGH", label: "大腿上方(左)" },
  { value: "RIGHT_UPPER_THIGH", label: "大腿上方(右)" },
  { value: "LEFT_LOWER_THIGH", label: "大腿下方(左)" },
  { value: "RIGHT_LOWER_THIGH", label: "大腿下方(右)" },
  { value: "LEFT_KNEE", label: "左膝蓋" },
  { value: "RIGHT_KNEE", label: "右膝蓋" },
  { value: "LEFT_CALF", label: "左小腿" },
  { value: "RIGHT_CALF", label: "右小腿" },
  { value: "LEFT_TOE", label: "左腳趾" },
  { value: "RIGHT_TOE", label: "右腳趾" },
];

export const RESIDENT_WOUND_POSITION_BACK_OPTION = [
  { value: "BACK_OF_HEAD", label: "後腦" },
  { value: "LEFT_SCAPULA", label: "左肩甲骨" },
  { value: "RIGHT_SCAPULA", label: "右肩甲骨" },
  { value: "BACK", label: "背部" },
  { value: "WAIST", label: "腰部" },
  { value: "COCCYX", label: "尾龍骨" },
  { value: "LEFT_HIP", label: "左臀部" },
  { value: "RIGHT_HIP", label: "右臀部" },
  { value: "LEFT_BACK_THIGH", label: "左大腿後" },
  { value: "RIGHT_BACK_THIGH", label: "右大腿後" },
  { value: "LEFT_BACK_CALF", label: "左小腿後" },
  { value: "RIGHT_BACK_CALF", label: "右小腿後" },
  { value: "LEFT_ELBOW", label: "左手肘" },
  { value: "RIGHT_ELBOW", label: "右手肘" },
  { value: "LEFT_BACK_HAND", label: "左手背" },
  { value: "RIGHT_BACK_HAND", label: "右手背" },
  { value: "LEFT_HEEL", label: "左腳踭" },
  { value: "RIGHT_HEEL", label: "右腳踭" },
];

export const RESIDENT_WOUND_POSITION_OPTION =
  RESIDENT_WOUND_POSITION_FRONT_OPTION.concat(
    RESIDENT_WOUND_POSITION_BACK_OPTION
  );

export const RESIDENT_WOUND_STATUS_OPTION = [
  { value: "UNRECOVERED", label: "未復原" },
  { value: "RECOVERED", label: "已復原" },
];

export const RESIDENT_WOUND_SOURCE_OPTION = [
  {
    value: "FOUND_IMMEDIATELY_AFTER_DISCHARGE_FROM_HOSPITAL",
    label: "醫院出院時即有",
  },
  {
    value: "FOUND_MOVE_IN_FROM_OTHER_HOSPITAL_OR_OTHER_HOME",
    label: "其他院/家裡來入住時即有",
  },
  { value: "FOUND_IN_OUR_HOSPITAL", label: "本院發現" },
];

export const RESIDENT_WOUND_DRESSING_OPTION = [
  { value: "SOLVENT", label: "溶液" },
  { value: "DRESSING", label: "敷料" },
];

export const RESIDENT_EMERGENCY_DISCHARGED_REASON_OPTION = [
  { value: "STABLE", label: "情況穩定" },
  { value: "TRANSFERRED", label: "轉院" },
  { value: "RECOVERED", label: "康復" },
  { value: "DEAD", label: "身亡" },
  { value: "OTHERS", label: "其他" },
];

export const RESIDENT_EMERGENCY_REASON_OPTION = [
  { value: "ABDOMINAL_PAIN", label: "肚痛" },
  { value: "ABNORMAL_URINATION", label: "小便不正常" },
  { value: "ABNORMAL_EXCRETION", label: "大便不正常" },
  { value: "ABNORMAL_VITAL_SIGNS", label: "生命表徵不正常" },
  { value: "BURNS", label: "燒傷" },
  { value: "FALLS", label: "跌倒" },
  { value: "VOMITING", label: "嘔吐" },
  { value: "OTHERS", label: "其他" },
];

export const EVALUATION_RESULT_OPTION = [
  { value: "NOT_EVALUATED", label: "未評核" },
  { value: "MODERATELY_IMPAIRED", label: "中度機能受損" },
  { value: "HIGHLY_IMPAIRED", label: "高度機能受損" },
];

export const RESIDENT_EMERGENCY_STATUS_OPTION = [
  { value: "IN_HOSPITAL", label: "留醫中" },
  { value: "DISCHARGED", label: "已出院" },
];

export const MEDICAL_ITEM_STATUS_OPTION = [
  { value: "APPOINTMENT", label: "預約中" },
  { value: "COMPLETED", label: "已完成" },
  { value: "CANCELLED", label: "已取消" },
];

export const FOLLOW_UP_STATUS_OPTION = [
  { value: "APPOINTMENT", label: "預約中" },
  { value: "COMPLETED", label: "已完成" },
  { value: "CANCELLED", label: "已取消" },
];

export const SCHEDULE_SESSION_TYPE = [
  { value: "REGULAR", label: "正常時間" },
  { value: "NON_REGULAR", label: "非正常時間" },
  { value: "BEFORE_MEAL", label: "餐前時段" },
];

export const LEAVE_STATUS_OPTIONS = [
  { value: "LEAVING", label: "外宿中" },
  { value: "RETURNED", label: "已回院" },
  { value: "CANCELLED", label: "已取消" },
];

export const DEATH_REASON_OPTIONS = [
  { value: "CANCER", label: "癌症" },
  { value: "HEART_FAILURE", label: "心臟衰竭" },
  { value: "OTHERS", label: "其他原因" },
];

export const DISCHARGE_STATUS_OPTIONS = [
  { value: "UNSETTLED", label: "未結算" },
  { value: "SETTLED", label: "已結算" },
];

export const DISCHARGE_REASON_OPTIONS = [
  { value: "TRANSFER", label: "轉院" },
  { value: "DEATH", label: "身故" },
  { value: "RECOVERY", label: "康復" },
  { value: "OTHERS", label: "其他" },
];

export const SEARCH_OPTIONS = [
  { value: "NAME", label: "院友姓名" },
  { value: "NUMBER", label: "訂位編號" },
];

export const TAKING_STATUS_OPTIONS = [
  { value: "INACTIVE", label: "未開始" },
  { value: "ACTIVE", label: "服用中" },
  { value: "USING", label: "使用中" },
  { value: "FINISHED", label: "已服完" },
];

export const TAKING_PERIOD_OPTIONS = [
  { value: "DAILY", label: "每日" },
  { value: "EVERY_N_DAY", label: "每#N日" },
  { value: "EVERY_WEEK", label: "每星期" },
  { value: "EVERY_N_WEEK", label: "每#N星期" },
  { value: "EVERY_N_DAY_OF_WEEK", label: "逢星期#N" },
  { value: "EVERY_N_MONTH", label: "每#N個月" },
  { value: "EVERY_OTHER_DAY", label: "隔天" },
  { value: "ODD_DAY", label: "單日" },
  { value: "EVEN_DAY", label: "雙日" },
];

export const INSTITUTION_TYPE_OPTIONS = [
  { value: "HOSPITAL", label: "醫院" },
  { value: "CLINIC", label: "診所" },
  { value: "PRIVATE_HOSPITAL", label: "私家醫院" },
  { value: "BTS", label: "輸血服務中心" },
];

export const PRESCRIPTION_TYPE_OPTIONS = [
  { value: "ORAL", label: "口服類" },
  { value: "EXTERNAL", label: "外用類" },
  { value: "SYRUP", label: "藥水類" },
  { value: "INJECTION", label: "針藥類" },
  { value: "GP", label: "GP類" },
];

export const TAKING_INSTRUCTION_OPTIONS = [
  // { value: "CONTAINED", label: "含服" },
  // { value: "SUBLINGUALLY", label: "舌下含服" },
  // { value: "EAR_DROPS_BOTH_EARS", label: "滴耳（雙耳）" },
  // { value: "EAR_DROPS_LEFT_EAR", label: "滴耳（左耳）" },
  // { value: "EAR_DROPS_RIGHT_EAR", label: "滴耳（右耳）" },
  // { value: "EYE_DROPS_BOTH_EYES", label: "滴眼（雙眼）" },
  // { value: "EYE_DROPS_LEFT_EYE", label: "滴眼（左眼）" },
  // { value: "EYE_DROPS_RIGHT_EYE", label: "滴眼（右眼）" },
  // { value: "INJECTION", label: "注射" },
  // { value: "APPLY", label: "搽用" },
  // { value: "EXTERNAL_USE", label: "外用" },
  // { value: "ANAL_PLUG", label: "塞肛" },
  // { value: "NASAL_SPRAY", label: "噴鼻" },
  // { value: "SUCTION", label: "吸服" },
  // { value: "ORAL", label: "口服" },
  // { value: "FOR_SHAMPOOING", label: "洗頭用" },
  // { value: "FOR_FOOT_WASHING", label: "洗腳用" },
  // { value: "UNDER_THE_TONGUE", label: "噴舌下" },

  { value: "PO", label: "口服" },
  { value: "EXT", label: "外用" },
  { value: "INH", label: "吸入法" },
  { value: "ID", label: "皮內注射" },
  { value: "IM", label: "肌肉注射" },
  { value: "IH", label: "皮下注射" },
  { value: "IV", label: "靜脈輸液" },
  { value: "PR", label: "經由直腸" },
  { value: "IP", label: "腹腔內" },
  { value: "PR_RECT_", label: "直腸用" },
  { value: "PR_VAGIN", label: "陰道用" },
  { value: "PR_URETHR", label: "尿道用" },
  { value: "PR2", label: "灌腸" },
  { value: "R_CAPIL", label: "頭髮用" },
  { value: "P_A_A_", label: "用於患部" },
  { value: "DEGL_", label: "咽/吞服" },
  { value: "PR_JUG_", label: "咽喉用" },
  { value: "PSP", label: "適量" },
  { value: "OCULIS", label: "雙眼" },
  { value: "O_L_", label: "左眼" },
  { value: "O_D_", label: "右眼" },
  { value: "AURIBUS", label: "雙耳" },
  { value: "AUR_D_", label: "右耳" },
  { value: "AUR_L", label: "左耳" },
  { value: "NASALIS", label: "鼻用" },
  { value: "NAR_", label: "鼻孔" },
  { value: "STILL_", label: "滴注" },
  { value: "GARG", label: "含嗽" },
  { value: "GTT_", label: "滴" },
  { value: "AQ", label: "水溶性" },
  { value: "CAP", label: "膠囊" },
  { value: "DIL", label: "稀釋" },
  { value: "OINT", label: "藥膏" },
  { value: "PIL", label: "丸劑" },
  { value: "SUSP", label: "懸浮液" },
  { value: "SUPP", label: "塞劑" },
  { value: "TAB", label: "錠劑" },
  { value: "PULV", label: "粉劑" },
  { value: "SYR", label: "糖漿" },
];

export const UNIT_OPTIONS = [
  { value: "TABLET", label: "粒" },
  { value: "GRAM", label: "克 (G)" },
  { value: "MG", label: "毫克 (G)" },
  { value: "UG", label: "微克 (MCG)" },
  { value: "ML", label: "毫升 (mL)" },
  { value: "IU", label: "國際單位 (IU)" },
  { value: "U", label: "U" },
  { value: "VIAL", label: "VIAL(支)" },
  { value: "DRIP", label: "滴" },
  { value: "PUFF", label: "口/puff" },
  { value: "APPROPRIATE", label: "適量" },
  { value: "PACKAGE", label: "包" },
  { value: "SPOON", label: "匙" },
];

export const GENDER_OPTIONS = [
  { value: "F", label: "女" },
  { value: "M", label: "男" },
];

// export const RELATIONSHIP_OPTIONS = [
//   { value: "PARENT_CHILD", label: "父母子女" },
//   { value: "SPOUSE", label: "夫婦" },
//   { value: "RELATIVES", label: "親屬" },
//   { value: "SOCIAL_WORKER", label: "社工" },
//   { value: "FRIENDS", label: "朋友" },
//   { value: "OTHERS", label: "其他" },
// ];

export const MARITAL_STATUS_OPTIONS = [
  { value: "SINGLE", label: "獨身" },
  { value: "MARRIED", label: "已婚" },
  { value: "DIVORCE_SEPARATED", label: "離婚/分居" },
  { value: "WIDOW", label: "鰥/寡" },
  { value: "COHABIT", label: "同居" },
];

export const EDUCATIONAL_BACKGROUND_OPTIONS = [
  { value: "NO", label: "沒有接受教育" },
  { value: "PRIMARY", label: "小學" },
  { value: "SECONDARY", label: "中學" },
  { value: "TERTIARY", label: "大專或以上" },
];


export const BED_TYPE_OPTIONS = [
  {
    value: "FUNDED_RESIDENTIAL_RESPITE_SERVICE",
    label: "資助住宿暫托照顧服務",
  },
  {
    value: "NON_FUNDED_RESIDENTIAL_RESPITE_SERVICE",
    label: "非資助住宿暫托照顧服務",
  },
  { value: "SUBSIDIARY_ELDERLY_HOME_PLACE", label: "資助安老宿位" },
  { value: "NON_SUBSIDIARY_ELDERLY_HOME_PLACE", label: "非資助安老宿位" },
  { value: "SINGLE_ROOM_PLACE", label: "單人房私位" },
  { value: "DOUBLE_ROOM_PLACE", label: "雙人房私位" },
  { value: "TRIPLE_ROOM_PLACE", label: "三人房私位" },
  { value: "QUADRUPLE_ROOM_PLACE", label: "四人房私位" },
  { value: "BOUGHT_PLACE", label: "買位" },
  {
    value: "ELDERLY_HOME_CARE_SERVICE_VOUCHERS",
    label: "長者院舍住宿照顧服務券",
  },
  {
    value: "OUT_PATIENT_CARE_HOSPITAL_SOCIAL_WORKER_CORPORATION",
    label: "離院照顧(醫社合作)",
  },
  { value: "ELDERLY_HOME_VOUCHERS", label: "院舍券" },
];

export const SELF_CARE_ABILITY_OPTIONS = [
  { value: "SELF_CARE", label: "全自理" },
  { value: "NORMAL", label: "普通護理" },
  { value: "COMPLETE", label: "全護理" },
  { value: "HIGHLY", label: "高度護理" },
  { value: "SPECIAL", label: "特別護理" },
];

export const VISUAL_OPTIONS = [
  { value: "NORMAL", label: "正常" },
  { value: "AMBLYOPIA", label: "弱視" },
  { value: "LIGHT_ONLY", label: "只能見光影" },
  { value: "BLIND", label: "失明" },
  { value: "CATARACT_GLAUCOMA", label: "白內障/青光眼" },
];

export const EATING_ABILITY_OPTIONS = [
  { value: "SELF", label: "自行進食" },
  { value: "ASSIST", label: "協助下完成進食" },
  { value: "HELP", label: "需要幫助餵食" },
  { value: "NASOGASTRIC_TUBE", label: "鼻胃管餵飼" },
];

export const HEARING_OPTIONS = [
  { value: "LOUDLY", label: "能聽到大聲說話" },
  { value: "NORMAL", label: "正常" },
  { value: "DEAF", label: "失聰" },
  { value: "HEARING_AID", label: "助聽器" },
  { value: "MOUTH_GESTURE", label: "看口形/手勢知意" },
];

export const COGNITIVE_ABILITY_OPTIONS = [
  { value: "NORMAL", label: "正常" },
  { value: "LOW", label: "輕度" },
  { value: "MEDIUM", label: "中度" },
  { value: "HIGH", label: "嚴重" },
];

export const HUG_OPTIONS = [
  { value: "NONE", label: "無" },
  { value: "ONE_PERSON", label: "1人" },
  { value: "TWO_PEOPLE", label: "2人" },
  { value: "THREE_PEOPLE", label: "3人" },
];

export const COMMUNICATION_ABILITY_OPTIONS = [
  { value: "CLEAR", label: "清楚" },
  { value: "SPEECH_BARRIER", label: "言語不通" },
  { value: "VAGUE", label: "含糊" },
  { value: "APHASIA", label: "失語" },
  { value: "SPEECH_DISORDER", label: "說話紊亂" },
  { value: "COMMUNICATE_IN_WRITING", label: "用書面溝通" },
];

export const EXCRETION_OPTIONS = [
  { value: "OWN", label: "自行如廁" },
  { value: "URINAL_POTTY", label: "用尿壺/便盆" },
  { value: "COMMODE_CHAIR", label: "用便椅" },
  { value: "DIAPERS", label: "用尿片" },
  { value: "URINARY_THROAT", label: "尿喉" },
  { value: "ANAL_BAG", label: "肛袋" },
];

export const AUXILIARY_TOOLS_OPTIONS = [
  { value: "NONE", label: "行動自如" },
  { value: "CRUTCH", label: "拐杖" },
  { value: "QUADRIPOD", label: "四腳架" },
  { value: "MOBILITY_AIDS", label: "助行器" },
  { value: "MOBILITY_AIDS_WHEELS", label: "有轆助行器" },
  { value: "SHOPPING_CART", label: "買餸車" },
  { value: "WHEELCHAIR", label: "輪椅" },
  { value: "MOTORWHEELCHAIR", label: "電動輪椅" },
  { value: "BEDRIDDEN", label: "臥床" },
  { value: "CRUTCH_W_CHAIR", label: "有椅拐杖" },
];

export const DENTURE_OPTIONS = [
  { value: "UP", label: "上" },
  { value: "DOWN", label: "下" },
  { value: "UP_DOWN", label: "上/下" },
];

export const RESERVATION_STATUS_OPTIONS = [
  { value: "RESERVING", label: "預約中" },
  { value: "CHECKED_IN", label: "已入住" },
  { value: "CANCELLED", label: "已取消" },
];

export const CHECK_IN_STATUS_OPTIONS = [
  { value: "CHECKED_IN", label: "已入住" },
  //{ value: 'RESERVING', label: '預約中' },
  { value: "DISCHARGED", label: "退院" },
  { value: "DIED", label: "身故" },
];

export const STOOL_RECORD_COLOR_OPTIONS = [
  { value: "BROWN", label: "啡色" },
  { value: "GREEN", label: "綠色" },
  { value: "YELLOW", label: "黃色" },
  { value: "BLACK", label: "黑色" },
  { value: "RED", label: "紅色" },
  { value: "WHITE", label: "白色" },
  { value: "OTHER", label: "其他" },
];

export const STOOL_RECORD_QUALITY_OPTIONS = [
  { value: "HARD", label: "硬質" },
  { value: "SOFT", label: "軟質" },
  { value: "LIQUID", label: "液態" },
  { value: "WATERY", label: "水狀" },
  { value: "FORMED", label: "成形" },
  { value: "MUCUSY", label: "黏液性" },
  { value: "OTHER", label: "其他" },
];


export const STOOL_RECORD_QUANTITY_OPTIONS = [
  { value: "SMALL", label: "少量" },
  { value: "MEDIUM", label: "中量" },
  { value: "LARGE", label: "大量" },
  { value: "VERY_LARGE", label: "極多" },
  { value: "OTHER", label: "其他" },
];

export const STOOL_RECORD_FREQUENCY_OPTIONS = [
  { value: "RARELY", label: "很少" },
  { value: "OCCASIONALLY", label: "偶爾" },
  { value: "MULTIPLE_TIMES_A_DAY", label: "一日多次" },
  { value: "OTHER", label: "其他" },
];

export const DOCUMENT_UPLOAD_STATUS_OPTIONS = [
  { value: "APPROVED", label: "已審核" },
  { value: "WAITING_FOR_APPROVAL", label: "待審核" },
];

export const STAFF_ROLE_SECTION_OPTIONS = [
  {
    value: "HOME_ALL",
    label: "院舍管理",
    icon: "HomeWorkOutlinedIcon",
    children: [
      { value: "HOME_DASHBOARD", label: "院舍總覽" },
      { value: "CALENDAR", label: "行事曆" },
      { value: "HOME_MANAGEMENT", label: "院舍管理" },
      { value: "BED_MANAGEMENT", label: "床位管理" },
      { value: "ROLE_MANAGEMENT", label: "用戶群組管理" },
      { value: "HOME_INFO", label: "院舍資料" },
    ],
  },
  {
    value: "RESIDENT_ALL",
    label: "院友管理",
    icon: "PeopleOutlineOutlinedIcon",
    children: [
      { value: "RESIDENT_MANAGEMENT", label: "院友總覽" },
      { value: "RESERVATION_MANAGEMENT", label: "訂位總覽" },
      { value: "DISCHARGE_MANAGEMENT", label: "退院總覽" },
      { value: "DEATH_RECORD", label: "死亡記錄" },
      { value: "LEAVE_RECORD", label: "院友外宿" },
      { value: "RESIDENT_STATISTICS", label: "院友統計" },
      { value: "RESIDENT_SETTING", label: "院友設定" },
    ],
  },
  {
    value: "ACCOUNTING_ALL",
    label: "會計管理",
    icon: "AccountBalanceWalletOutlinedIcon",
    children: [
      { value: "DEFAULT_EXPENSE", label: "預設費用" },
      { value: "RESIDENT_INVOICE", label: "院友發票" },
      { value: "HOME_EXPENSE", label: "院舍支出" },
      { value: "RESIDENT_DEPOSIT", label: "院友按金" },
      { value: "ACCOUNTING_SETTING", label: "會計設定" },
      { value: "STAFF_SALARY", label: "員工薪金" }
    ],
  },
  {
    value: "MEDICINE_ALL",
    label: "藥物管理",
    icon: "InsertLinkOutlinedIcon",
    children: [
      { value: "MEDICINE_MANAGEMENT", label: "藥物總覽" },
      { value: "MEDICINE_REPORT", label: "藥物報表" },
      { value: "MEDICINE_SETTING", label: "藥物設定" },
    ],
  },
  {
    value: "MEDICAL_ALL",
    label: "醫療記錄",
    icon: "LocalHospitalOutlinedIcon",
    children: [
      { value: "MEDICAL_RECORD_MANAGEMENT", label: "病歷管理" },
      { value: "RESIDENT_FOLLOW_UP_MANAGEMENT", label: "覆診管理" },
      { value: "MEDICAL_ITEM", label: "醫療項目" },
      { value: "RESIDENT_EMERGENCY_RECORD_MANAGEMENT", label: "急症記錄" },
      { value: "MEDICAL_SETTING", label: "醫療設定" },
    ],
  },
  {
    value: "NURSING_ALL",
    label: "護理記錄",
    icon: "FavoriteBorderOutlinedIcon",
    children: [
      { value: "RESIDENT_VITAL_SIGN", label: "生命表徵" },
      { value: "WOUND_MANAGEMENT", label: "傷口管理" },
      { value: "RESIDENT_RESTRAINT_ITEM", label: "約束物品" },
      { value: "ICP", label: "ICP" },
      { value: "NURSING_SETTING", label: "護理設定" },
    ],
  },
  {
    value: "STAFF_ALL",
    label: "員工管理",
    icon: "PermContactCalendarOutlinedIcon",
    children: [
      { value: "STAFF_USERS", label: "員工資料" },
      { value: "ROSTER_RECURRING", label: "編制更表" },
      { value: "ROSTER_MANAGEMENT", label: "更表管理" },
    ],
  },
  {
    value: "WRISTBAND_ALL",
    label: "手帶管理",
    icon: "WatchOutlinedIcon",
    children: [
      { value: "WRISTBAND_MANAGEMENT", label: "手帶總覽" },
      { value: "WRISTBAND_SETTING", label: "手帶設定" },
    ],
  },
  {
    value: "IBEACON_ALL",
    label: "防遊走管理",
    icon: "WatchOutlinedIcon",
    children: [
      { value: "IBEACON_MANAGEMENT", label: "防遊走總覽" },
      { value: "IBEACON_SETTING", label: "防遊走設定" },
    ],
  },
  {
    value: "DOCUMENT_ALL",
    label: "文件管理",
    icon: "FolderOpenOutlinedIcon",
    children: [
      { value: "DOCUMENT_LISTING", label: "文件總覽" },
      { value: "DOCUMENT_MANAGEMENT", label: "文件管理" },
    ],
  },
];
