import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL, ROUTES } from "../../../constants";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./documentUpload/FilteringForm";
import { ListingPageHeader } from "./documentUpload/ListingPageHeader";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function ResidentDocumentUploadPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  let { id } = useParams();

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.resident = params.resident;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_DOCUMENT + "?resident=" + id,
      getOne: API_URL.RESIDENT_DOCUMENT,
      create: API_URL.RESIDENT_DOCUMENT,
      edit: API_URL.RESIDENT_DOCUMENT,
      delete: API_URL.RESIDENT_DOCUMENT,
    });
  }, [home]);

  return (
    <ListingPageHeader
      handleFilterChange={handleFilterChange}
      filteringParams={filteringParams}
      createFilteringParams={createFilteringParams}
    />
  );
}
