import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API_URL } from "../../constants";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Notification } from "../../components";
import Button from "@material-ui/core/Button";
import { PageHeader } from "../../components";
import fileDownload from "js-file-download";
import moment from "moment";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import FormikDateField from "../../components/inputs/FormikDateField";
// import { Formik, useFormikContext } from "formik";
// import {
//     Chart,
//     ArgumentAxis,
//     ValueAxis,
//     LineSeries,
//     Title,
//     Legend,
// } from '@devexpress/dx-react-chart-material-ui';
// import { Animation } from '@devexpress/dx-react-chart';
// import * as Yup from 'yup';
// import { GridColumnsToolbarButton } from '@material-ui/data-grid';
// import FormikTextField from "../../components/inputs/FormikTextField";
// import TextField from '@material-ui/core/TextField';
// import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
// import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";

/*const initialValues = {
    startDate: '',
    endDate: '',
}

const validationSchema = Yup.object().shape({
    startDate:Yup.date()
    .required("required")
    .typeError("error type")
    .nullable(),
    endDate:Yup.date()
    .required("required")
    .typeError("error type")
    .nullable(),
});*/

// const data = [
//     { argument: 1, value: 10 },
//     { argument: 2, value: 20 },
//     { argument: 3, value: 30 },
// ];

const useStyles = makeStyles((theme) => ({
  table: {
    [theme.breakpoints.down("md")]: {
      "& tr, & thead, & tbody, & tfoot, & th, & td": {
        display: "block",
      },
    },
    marginBottom: 25,
  },
  logo: {
    maxWidth: 150,
    maxHeight: 150,
  },
  rowHead: {
    width: 250,
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderBottom: 0,
    },
  },
  button: {
    marginRight: theme.spacing(3),
  },
}));

export default function ResidentStatisticsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [entity, setEntity] = useState({});

  const onPrint = (values, formikActions) => {
    axios
      .get("/api/residents/exportDining", {
        params: {
          home: home?.home?.id,
        },
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_用餐統計_export.xlsx"
        );
      });
  };

  const getEntity = () => {
    if (home.home) {
      axios
        .get(API_URL.RESIDENT + "/getSpecificHomeExtraStat", {
          params: {
            home: home.home.id,
          },
        })
        .then((response) => {
          const data = response.data;
          setEntity(data);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    getEntity();
  }, [home]);

  return (
    <div>
      <PageHeader title={"院友統計"} />
      <Notification />
      {/* <Formik
                initialValues={{
                    status: "",
                    startDate: "",
                    endDate: "",
                    type: "",
                    search: "",
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                }) => (
                    <Fragment> */}

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Paper component={Paper} style={{ padding: 20 }}>
            <Grid container spacing={2}>
              <Grid item md={9} xs={12} style={{ margin: 0 }}>
                <Typography variant="h4" style={{ marginBottom: 10 }}>
                  人數統計
                </Typography>
              </Grid>
              <Grid item md={3} xs={12} align="right">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<OpenInBrowserOutlinedIcon />}
                  onClick={() => onPrint()}
                >
                  用餐統計
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12} style={{ margin: 0 }}>
                <TableContainer>
                  <Table className={classes.table} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.rowHead}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          總床位
                        </TableCell>
                        <TableCell>{entity.homeBedCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          全院人數
                        </TableCell>
                        <TableCell>{entity.registeredResidentCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          實住人數
                        </TableCell>
                        <TableCell>
                          {entity.registeredResidentCount -
                            entity.residentInHospital -
                            entity.residentLeaveCount}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          留醫人數
                        </TableCell>
                        <TableCell>{entity.residentInHospital}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          外宿人數
                        </TableCell>
                        <TableCell>{entity.residentLeaveCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          新收人數 (*過去30天)
                        </TableCell>
                        <TableCell>{entity.last30DaysCheckInCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          死亡人數
                        </TableCell>
                        <TableCell>{entity.residentDeathCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          男院友
                        </TableCell>
                        <TableCell>{entity.residentMaleCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          女院友
                        </TableCell>
                        <TableCell>{entity.residentFemaleCount}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={6} xs={12}>
                <TableContainer>
                  <Table className={classes.table} size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.rowHead}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          需要氧氣院友
                        </TableCell>
                        <TableCell>{entity.residentOxygenCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          &nbsp;
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          自理能力
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          全自理
                        </TableCell>
                        <TableCell>{entity.selfCareCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          普通護理
                        </TableCell>
                        <TableCell>{entity.normalCareCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          全護理
                        </TableCell>
                        <TableCell>{entity.completeCareCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          高度護理
                        </TableCell>
                        <TableCell>{entity.highlyCareCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          特別護理
                        </TableCell>
                        <TableCell>{entity.specialCareCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.rowHead}>
                          未設定
                        </TableCell>
                        <TableCell>{entity.undefinedCareCount}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* <Grid item md={7} xs={12}>
                                <Paper style={{ padding: 20 }}>
                                    <Typography variant="h4" style={{ marginBottom: 10 }}>床位統計</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} style={{ marginBottom: 15 }}>
                                            <fieldset>
                                                <FormLabel component="legend">搜尋</FormLabel>
                                                <Grid container spacing={3}>
                                                    <Grid item md={4} xs={12}>
                                                        <FormikDateField label="搜尋日期由" name="startDate" />
                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <FormikDateField label="搜尋日期至" name="endDate" />
                                                    </Grid>
                                                    <Grid item md={4} xs={12} style={{ alignSelf: 'center' }}>
                                                        <Button
                                                            className={classes.button}
                                                            color="primary"
                                                            variant="contained"
                                                            //onClick={handleSubmit}
                                                            disabled={isSubmitting}
                                                            startIcon={<SearchOutlinedIcon />}
                                                        >
                                                            篩選
                                                        </Button>
                                                        <Button
                                                            className={classes.button}
                                                            color="default"
                                                            variant="outlined"
                                                            //onClick={handleReset}
                                                            disabled={isSubmitting}
                                                            startIcon={<RotateLeftOutlinedIcon />}
                                                        >
                                                            重設
                                                        </Button>
                                                    </Grid>

                                                </Grid>
                                            </fieldset>
                                        </Grid>
                                    </Grid>
                                    <Chart data={data}>
                                        <ArgumentAxis />
                                        <ValueAxis />
                                        <LineSeries valueField="value" argumentField="argument" />
                                    </Chart>
                                </Paper>
                            </Grid> */}
      </Grid>
      {/* </Fragment>
                )}
            </Formik> */}
    </div>
  );
}
