import * as Yup from "yup";
import moment from "moment";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import FormikDateTimeField from "../../../../components/inputs/FormikDateTimeField";
import axios from "axios";
import { API_URL, FOLLOW_UP_STATUS_OPTION } from "../../../../constants";
import FormikReferenceField from "../../../../components/inputs/FormikReferenceField";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";

export const initialValues = {
  resident: "",
  recordDateTime: "",
  remarks: "",
};

export const validationSchema = Yup.object().shape({
  recordDateTime: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  remarks: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [beds, setBeds] = useState([]);
  const [infoEntity, setInfoEntity] = useState({});
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    if (entity?.id) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  useEffect(() => {
    if (entity?.id) {
      axios
        .get(API_URL.RESIDENT + "/" + entity.resident.id)
        .then((response) => {
          setInfoEntity(response.data);
          setLoaded(true);
        })
        .catch((error) => {});
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
              膳食記錄資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikDateTimeField
                  required
                  label="膳食記錄日期及時間"
                  name="recordDateTime"
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="備註" name="remarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
