import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import {
  API_URL,
  ROUTES,
  STOOL_RECORD_COLOR_OPTIONS,
  STOOL_RECORD_QUALITY_OPTIONS,
  STOOL_RECORD_QUANTITY_OPTIONS,
  STOOL_RECORD_FREQUENCY_OPTIONS,
} from "../../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./StoolRecord/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./StoolRecord/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ListingPageHeader } from "./StoolRecord/ListingPageHeader";
import { useParams } from "react-router-dom";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { NotificationActions } from "../../../actions";
import { EditDialog } from "./StoolRecord/EditDialog";

export default function StoolRecord() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  let { id } = useParams();
  const [editEntity, setEditEntity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [editOpen, setEditOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.recordDateTime = values.recordDateTime;
    params.remarks = values.remarks;
    params.color = values.color;
    params.quality = values.quality;
    params.quantity = values.quantity;
    params.frequency = values.frequency;
    params.resident = id;

    return params;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 160,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton color="default" size="small" disabled={loading}>
                                <AssignmentIndOutlinedIcon />
                            </IconButton> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "createdAt",
        headerName: "記錄日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]?.substring(0, 10)
            : "--";
        },
      },
      {
        field: "recordDateTime",
        headerName: "大便記錄日期及時間",
        width: 170,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return moment(params.row[params.field]).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "color",
        headerName: "顏色",
        width: 170,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return STOOL_RECORD_COLOR_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "quality",
        headerName: "素質",
        width: 170,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return STOOL_RECORD_QUALITY_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "quantity",
        headerName: "數量",
        width: 170,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return STOOL_RECORD_QUANTITY_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "frequency",
        headerName: "頻率",
        width: 170,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return STOOL_RECORD_FREQUENCY_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },

      {
        field: "remarks",
        headerName: "備註",
        width: 380,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  const onEdit = (values, formikActions) => {
    axios
      .put(API_URL.STOOL_RECORD + "/" + values.id, {
        recordDateTime: values.recordDateTime,
        color: values.color,
        quality: values.quality,
        quantity: values.quantity,
        frequency: values.frequency,
        remarks: values.remarks,
        resident: id,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setEditEntity(null);
        formikActions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.STOOL_RECORD + "?resident=" + id,
      getOne: API_URL.STOOL_RECORD,
      create: API_URL.STOOL_RECORD,
      edit: API_URL.STOOL_RECORD,
      delete: API_URL.STOOL_RECORD,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="大便記錄"
      editableFields={[
        "recordDateTime",
        "color",
        "quality",
        "quantity",
        "frequency",
        "remarks",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      // createFilteringParams={createFilteringParams}
      // normalizeReceivedValues={normalizeReceivedValues}
      // filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={
        {
          // handleFilterChange: handleFilterChange,
        }
      }
      maxWidth="md"
      defaultSorting={"updatedAt,desc"}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setLoading(false);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        // normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
