import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import axios from "axios";

export const initialValues = {
  resident: "",
  issueDateTime: moment(new Date()).toISOString(),
  // dueDate: moment(new Date()).format("YYYY-MM-DD"),
  status: "NOT_YET_PAID",
  residentInvoiceItems: [],
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  issueDateTime: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  dueDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  residentInvoiceItems: Yup.array().of(
    Yup.object().shape({
      // since invoiceItem structured could be an object: {id: xxx} or just string of id: xxx, thus here used mixed()
      invoiceItem: Yup.mixed()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      qty: Yup.string()
        .matches(/^[1-9][0-9]*$/, "輸入必須為正整數")
        .required(<FormattedMessage id="validation.message.required" />),
      price: Yup.number()
        .min(0, "輸入必須為0或正數")
        .required(<FormattedMessage id="validation.message.required" />),
    })
  ),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [beds, setBeds] = useState([]);
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [defaultExpenseItems, setDefaultExpenseItems] = useState([]);
  const [selectedResident, setSelectedResident] = useState("");
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();
  const total = [];

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      values.resident = "";
      // here not use initial values because the residenInvoiceItems in initialValues will be kept if dialog close and then open again
      resetForm({ values: {
        resident: "",
        issueDateTime: moment(new Date()).toISOString(),
        status: "NOT_YET_PAID",
        residentInvoiceItems: [],
      } });
    }
  }, [entity]);

  useEffect(() => {
    if (values?.resident) {
      if (selectedResident == values.resident) return;
      setSelectedResident(values.resident);

      axios
        .get(`${apiUrl.getDefaultExpenseItems ? apiUrl.getDefaultExpenseItems : apiUrl}/${values.resident.id || values.resident}`)
        .then((response) => {
          let data = response?.data;
          setDefaultExpenseItems(data.defaultExpenseItemViewList);
        })
        .catch((error) => {})
        .finally(() => {
        });
    } else {
      setDefaultExpenseItems([]);
    }
  }, [values]);

  const handleBatchAddDefaultExpenseItems = (defaultExpenseItems) => {
    // console.debug("defaultExpenseItems", defaultExpenseItems);
    defaultExpenseItems.forEach(item => {
      values.residentInvoiceItems.push({
        invoiceItem: item.invoiceItem,
        price: item.price,
        qty: item.qty,
        description: item.description,
      });
    });
    setDefaultExpenseItems([]);
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="院友姓名"
                  name="resident"
                  apiUrl={
                    "/api/homes/" + home.home.id + "/residents/references"
                  }
                  getOptionLabel={(option) =>
                    option.lastnameTc + option.firstnameTc
                  }
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormikDateTimeField
                  required
                  label="發票日期及時間"
                  name="issueDateTime"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormikDateField required label="到期日" name="dueDate" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12} xs={12}>
        <fieldset>
          <FormLabel component="legend">收費項目資料</FormLabel>
          <FieldArray
            name="residentInvoiceItems"
            render={(arrayHelpers) => (
              <div>
                <Table stickyHeader padding="none" style={{ marginBottom: 15 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        omponent="th"
                        style={{ padding: 10, minWidth: 250 }}
                      >
                        收費項目
                      </TableCell>
                      <TableCell
                        omponent="th"
                        style={{ padding: 10, minWidth: 250 }}
                      >
                        描述
                      </TableCell>
                      <TableCell
                        omponent="th"
                        style={{ padding: 10, minWidth: 100 }}
                      >
                        數量
                      </TableCell>
                      <TableCell
                        omponent="th"
                        style={{ padding: 10, minWidth: 100 }}
                      >
                        單價
                      </TableCell>
                      <TableCell
                        align="center"
                        omponent="th"
                        style={{ padding: 10, minWidth: 100 }}
                      >
                        總數
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ width: 50, minWidth: 50 }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.residentInvoiceItems &&
                      values.residentInvoiceItems.map((item, index) => (
                        <TableRow key={index} disablegutters>
                          <TableCell style={{ paddingRight: 1 }}>
                            <FormikReferenceField
                              edit={entity ? true : false}
                              required
                              styles={{
                                marginTop: "-4px",
                              }}
                              label="收費項目"
                              apiUrl={"/api/invoiceItems?type=INVOICE"}
                              getOptionLabel={(option) => option?.nameTc}
                              getOptionValue={(option) => option?.id}
                              name={`residentInvoiceItems.${index}.invoiceItem`}
                              apiParams={{
                                unpaged: true,
                              }}
                              preSelectedOption={item?.invoiceItem}
                            />
                          </TableCell>
                          <TableCell style={{ paddingRight: 1 }}>
                            <FormikTextField
                              label="描述"
                              name={`residentInvoiceItems.${index}.description`}
                            />
                          </TableCell>
                          <TableCell style={{ paddingRight: 1 }}>
                            <FormikTextField
                              required
                              type="number"
                              label="數量"
                              name={`residentInvoiceItems.${index}.qty`}
                              defaultValue="0"
                            />
                          </TableCell>
                          <TableCell style={{ paddingRight: 10 }}>
                            <FormikTextField
                              required
                              type="number"
                              label="單價"
                              name={`residentInvoiceItems.${index}.price`}
                              defaultValue="0"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {values.residentInvoiceItems[index].qty &&
                            values.residentInvoiceItems[index].price
                              ? "$ " +
                                Number(
                                  (
                                    values.residentInvoiceItems[index].qty *
                                    values.residentInvoiceItems[index].price
                                  ).toFixed(2)
                                )
                              : "$ " + 0}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <Button
                  color="default"
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => arrayHelpers.push("")}
                >
                  新增
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() =>
                    handleBatchAddDefaultExpenseItems(defaultExpenseItems)
                  }
                  disabled={!defaultExpenseItems?.length}
                  style={{ marginLeft: 10 }}
                >
                  加入已選擇院友的預設項目
                </Button>
                <Typography variant="h4" component="h2" align="right">
                  {"賬單總數: $ " +
                    Number(
                      (
                        values.residentInvoiceItems &&
                        values.residentInvoiceItems.map((item) =>
                          item.qty && item.price ? item.qty * item.price : 0
                        )
                      )
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2)
                    )}
                </Typography>
              </div>
            )}
          />
        </fieldset>
      </Grid>
    </Grid>
  );
};
